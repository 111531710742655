import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TextContent = styled.div(() => {
	return css({
		'> *': {
			'> li': {
				marginBottom: 8,
				marginLeft: 16,
				paddingLeft: 8,
			},
			'&:not(:last-child)': { marginBottom: 21 },
		},
	});
});
