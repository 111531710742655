import * as Styled from './ColumnFilter.style';
import { MKColumn } from '../Table';
import { Dropdown } from '@kinsta/dropdown';
import { Dispatch, SetStateAction } from 'react';
import ColumnFilterButton from './ColumnFilterButton';
import ColumnFilterMenu from './ColumnFilterMenu';

type Props = {
	columns: MKColumn[];
	hiddenColumns: string[];
	setHiddenColumns: Dispatch<SetStateAction<string[]>>;
};

const ColumnFilter = (props: Props) => {
	return (
		<Styled.Container>
			<Dropdown floatingElement={<ColumnFilterMenu {...props} />}>
				<ColumnFilterButton hiddenColumns={props.hiddenColumns} />
			</Dropdown>
		</Styled.Container>
	);
};

export default ColumnFilter;
