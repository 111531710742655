/* eslint-disable */
import * as Types from '../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type DateTimeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DateTimeQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', noData: { __typename?: 'NoDataTranslation', na: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const DateTimeDocument = gql`
    query DateTime {
  user {
    id
    ... on Affiliate {
      translation {
        noData {
          na
        }
      }
    }
  }
}
    `;

/**
 * __useDateTimeQuery__
 *
 * To run a query within a React component, call `useDateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDateTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useDateTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DateTimeQuery, DateTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DateTimeQuery, DateTimeQueryVariables>(DateTimeDocument, options);
      }
export function useDateTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DateTimeQuery, DateTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DateTimeQuery, DateTimeQueryVariables>(DateTimeDocument, options);
        }
export type DateTimeQueryHookResult = ReturnType<typeof useDateTimeQuery>;
export type DateTimeLazyQueryHookResult = ReturnType<typeof useDateTimeLazyQuery>;
export type DateTimeQueryResult = Apollo.QueryResult<DateTimeQuery, DateTimeQueryVariables>;