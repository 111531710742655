import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/react';
import { StratusProvider } from '@kinsta/stratus-core';
import { AppProps } from 'next/app';
import { Slide, ToastContainer } from 'react-toastify';

import { GTM, Layout } from '@/src/app-specific-components';
import baseCss from '@/src/baseCss';
import { ErrorBoundary, Head } from '@/src/components';
import PreLogInLanguageContext from '@/src/context/PreLogInLanguageContext';
import { useApollo } from '@/src/utilities/apollo';

const MyApp = ({ Component, pageProps, router }: AppProps) => {
	const apolloClient = useApollo(pageProps.initialApolloState);

	return (
		<>
			<ErrorBoundary refreshPage={true} variant='page'>
				<ApolloProvider client={apolloClient}>
					<StratusProvider language='en'>
						<PreLogInLanguageContext selectLanguage=''>
							<ToastContainer
								hideProgressBar
								draggable={false}
								transition={Slide}
								closeOnClick={false}
								autoClose={10000}
							/>

							<Layout router={router}>
								<Head>
									<meta charSet='utf-8' />
									<meta
										name='viewport'
										content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
									/>
									<meta
										name='description'
										content='The Kinsta Affiliate system.'
									/>
									<meta content='Kinsta' name='author' />
									<link
										rel='apple-touch-icon'
										sizes='180x180'
										href='/icons/apple-touch-icon.png'
									/>
									<link
										rel='icon'
										type='image/png'
										sizes='32x32'
										href='/icons/favicon-32.ico'
									/>
									<link
										rel='icon'
										type='image/png'
										sizes='16x16'
										href='/icons/favicon-16.ico'
									/>
									<link rel='manifest' href='/icons/manifest.json' />
									<link
										rel='mask-icon'
										href='/icons/safari-pinned-tab.svg'
										color='#ffffff'
									/>
									<meta name='msapplication-TileColor' content='#ffffff' />
									<meta name='apple-touch-fullscreen' content='yes' />
									<meta name='apple-mobile-web-app-capable' content='yes' />
									<meta
										name='apple-mobile-web-app-title'
										content='Kinsta Affiliate System'
									/>
									<meta
										name='apple-mobile-web-app-status-bar-style'
										content='white'
									/>
									<meta name='theme-color' content='#ffffff' />
									<meta name='msapplication-TileColor' content='#ffffff' />
									<meta
										name='msapplication-TileImage'
										content='/icons/mstile-150x150.png'
									/>
									<meta name='robots' content='noindex' />
									<meta
										property='og:image'
										content='https://affiliate.kinsta.com/kattribution-opengraph.png'
									/>
									<meta property='og:image:type' content='image/png' />
									<meta property='og:image:width' content='2400' />
									<meta property='og:image:height' content='1260' />
									<meta property='og:type' content='website' />
								</Head>
								<GTM />
								<Global styles={baseCss} />

								<Component {...pageProps} />
							</Layout>
						</PreLogInLanguageContext>
					</StratusProvider>
				</ApolloProvider>
			</ErrorBoundary>

			<div id='__copy' />
		</>
	);
};

export default MyApp;
