import { format, parseISO } from 'date-fns';

import * as Styled from '@/src/components/Chart/ChartTooltip.style';

type Payload = {
	payload: {
		value: number;
		percentChange: number;
	};
};

type Props = {
	label: string;
	payload: [Payload];
};

const ChartTooltip = ({ label: date, payload }: Props) => {
	const data = payload[0]?.payload;
	if (!data) {
		return <></>;
	}

	const { value } = data;
	if (!date || typeof value === 'undefined') {
		return <></>;
	}

	const { percentChange } = data;

	return (
		<Styled.ChartTooltip>
			<span>{format(parseISO(date), 'MMM d')}:</span>
			<strong>{value}</strong>

			{percentChange && (
				<span>
					{percentChange > 0 ? (
						<Styled.UpIcon type='External' />
					) : (
						<Styled.DownIcon type='External' />
					)}
					<span>{percentChange}%</span>
				</span>
			)}
		</Styled.ChartTooltip>
	);
};

export default ChartTooltip;
