import { Icon } from '@kinsta/icons';
import { Tooltip } from '@kinsta/tooltip';

import * as Styled from '@/src/components/Table/Pagination/Pagination.style';

type Props = {
	pageIndex: number;
	pageCount: number;
	gotoPage: (page: number) => void;
};

const Pagination = ({ pageIndex, pageCount = 1, gotoPage }: Props) => {
	const buttons = Array.from(Array(pageCount), (_, i) => {
		// We always want the first and last element, so just capture/return them here.
		if (i === 0 || i + 1 === pageCount) {
			return (
				<li key={i}>
					<Styled.PaginationButton
						aria-label={
							i === pageIndex ? 'Current page' : `Go to page ${i + 1}`
						}
						aria-current={i === pageIndex}
						selected={i === pageIndex}
						onClick={() => {
							return gotoPage(i);
						}}
					>
						{i + 1}
					</Styled.PaginationButton>
				</li>
			);
		}

		// We want to show the 2nd and 3rd (or last and next-to-last) elements if the first or last page is selected.
		if (pageIndex < 4 && i < 5) {
			return (
				<li key={i}>
					<Styled.PaginationButton
						aria-label={
							i === pageIndex ? 'Current page' : `Go to page ${i + 1}`
						}
						aria-current={i === pageIndex}
						selected={i === pageIndex}
						onClick={() => {
							return gotoPage(i);
						}}
					>
						{i + 1}
					</Styled.PaginationButton>
				</li>
			);
		}
		if (pageIndex > pageCount - 5 && i > pageCount - 6) {
			return (
				<li key={i}>
					<Styled.PaginationButton
						aria-label={
							i === pageIndex ? 'Current page' : `Go to page ${i + 1}`
						}
						aria-current={i === pageIndex}
						selected={i === pageIndex}
						onClick={() => {
							return gotoPage(i);
						}}
					>
						{i + 1}
					</Styled.PaginationButton>
				</li>
			);
		}

		if (pageCount > 4) {
			// Prepended dots
			if (i < pageIndex - 1) {
				if (i === 1) {
					return (
						<li key={i}>
							<Tooltip content='Go back 5'>
								<Styled.PaginationButton
									aria-label={
										pageIndex - 5 < 0
											? 'Go to first page'
											: `Go to page ${pageIndex - 5}`
									}
									selected={i === pageIndex}
									onClick={() => {
										if (pageIndex - 5 < 0) {
											return gotoPage(0);
										}

										return gotoPage(pageIndex - 5);
									}}
								>
									<Icon type='More' style={{ transform: 'rotate(90deg)' }} />
								</Styled.PaginationButton>
							</Tooltip>
						</li>
					);
				}

				return null;
			}

			// Appended dots
			if (i > pageIndex + 1) {
				if (i === pageCount - 2) {
					return (
						<li key={i}>
							<Tooltip content='Go forward 5'>
								<Styled.PaginationButton
									aria-label={
										pageIndex + 5 > pageCount - 1
											? 'Go to last page'
											: `Go to page ${pageIndex + 5}`
									}
									selected={i === pageIndex}
									onClick={() => {
										if (pageIndex + 5 > pageCount - 1) {
											return gotoPage(pageCount - 1);
										}

										return gotoPage(pageIndex + 5);
									}}
								>
									<Icon type='More' style={{ transform: 'rotate(90deg)' }} />
								</Styled.PaginationButton>
							</Tooltip>
						</li>
					);
				}

				return null;
			}
		}

		return (
			<li key={i}>
				<Styled.PaginationButton
					aria-label={i === pageIndex ? 'Current page' : `Go to page ${i + 1}`}
					aria-current={i === pageIndex}
					selected={i === pageIndex}
					onClick={() => {
						return gotoPage(i);
					}}
				>
					{i + 1}
				</Styled.PaginationButton>
			</li>
		);
	});

	return <>{buttons}</>;
};

export default Pagination;
