import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { Icon as KIcon } from '@kinsta/icons';
import { transparentize } from 'polished';

const { gutter, color } = constants;

type Props = {
	isActive: boolean;
};

export const Link = styled.a<Props>(({ isActive }) => {
	return css(
		{
			color: color.white,
			textDecoration: 'none',
			height: 40,
			display: 'grid',
			gridTemplateColumns: 'auto auto',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: gutter * 2,
			cursor: 'pointer',
		},
		!isActive && {
			'&:hover > div, &:focus > div': {
				background: transparentize(0.8, color.purple),
			},
			'&:active > div': { background: transparentize(0.7, color.purple) },
		},
	);
});

export const IconWrap = styled.div<Props>(({ isActive }) => {
	return css(
		{
			width: 32,
			height: 32,
			display: 'grid',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 999,
			transition: 'background .3s',
		},
		isActive && { background: color.purple },
	);
});

export const Icon = styled(KIcon)(() => {
	return css({
		width: 24,
		height: 24,
	});
});
