/* eslint-disable */
import * as Types from '../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type MakeAffiliateLinkModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MakeAffiliateLinkModalQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', kaid: string, id: string, translation: { __typename?: 'Translation', menu: { __typename?: 'MenuTranslation', makeLink: string }, settings: { __typename?: 'SettingsTranslation', errors: { __typename?: 'ErrorsTranslation', fieldRequired: string, cannotBeLongerThan: string, characters: string, mustBeAtLeast: string, charactersLong: string, correctInformation: string, errorLoadingCategoryList: string, errorLoadingCountryList: string } }, createAffiliateLink: { __typename?: 'CreateAffiliateLinkTranslation', modalTitle: string, modalDescription: string, modalUrlPlaceholder: string, modalGeneratedLink: string, affiliateLink: string, back: string, createLink: string, close: string, cancel: string, affiliateUrl: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const MakeAffiliateLinkModalDocument = gql`
    query MakeAffiliateLinkModal {
  user {
    id
    ... on Affiliate {
      kaid
      translation {
        menu {
          makeLink
        }
        settings {
          errors {
            fieldRequired
            cannotBeLongerThan
            characters
            mustBeAtLeast
            charactersLong
            correctInformation
            errorLoadingCategoryList
            errorLoadingCountryList
          }
        }
        createAffiliateLink {
          modalTitle
          modalDescription
          modalUrlPlaceholder
          modalGeneratedLink
          affiliateLink
          back
          createLink
          close
          cancel
          affiliateUrl
        }
      }
    }
  }
}
    `;

/**
 * __useMakeAffiliateLinkModalQuery__
 *
 * To run a query within a React component, call `useMakeAffiliateLinkModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMakeAffiliateLinkModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMakeAffiliateLinkModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useMakeAffiliateLinkModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MakeAffiliateLinkModalQuery, MakeAffiliateLinkModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MakeAffiliateLinkModalQuery, MakeAffiliateLinkModalQueryVariables>(MakeAffiliateLinkModalDocument, options);
      }
export function useMakeAffiliateLinkModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MakeAffiliateLinkModalQuery, MakeAffiliateLinkModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MakeAffiliateLinkModalQuery, MakeAffiliateLinkModalQueryVariables>(MakeAffiliateLinkModalDocument, options);
        }
export type MakeAffiliateLinkModalQueryHookResult = ReturnType<typeof useMakeAffiliateLinkModalQuery>;
export type MakeAffiliateLinkModalLazyQueryHookResult = ReturnType<typeof useMakeAffiliateLinkModalLazyQuery>;
export type MakeAffiliateLinkModalQueryResult = Apollo.QueryResult<MakeAffiliateLinkModalQuery, MakeAffiliateLinkModalQueryVariables>;