import md5 from 'md5';

import { useSidebarUserQuery } from '@/src/app-specific-components/Sidebar/SidebarUser/SidebarUser.queries.gen';
import * as Styled from '@/src/app-specific-components/Sidebar/SidebarUser/SidebarUser.style';

const SidebarUser = () => {
	const { data, loading } = useSidebarUserQuery();

	if (loading) {
		return null;
	}

	if (data?.user?.__typename !== 'Affiliate') {
		return null;
	}

	return (
		<Styled.SidebarUser>
			<Styled.UserAvatar
				src={`https://www.gravatar.com/avatar/${md5(
					data.user.email.toLowerCase().trim(),
				)}`}
				alt='User Icon'
			/>
			<Styled.UserDetails>
				<span>
					{data.user.translation.menu.hello} {data.user.firstName}
				</span>
				<Styled.UserId>{data.user.kaid}</Styled.UserId>
			</Styled.UserDetails>
		</Styled.SidebarUser>
	);
};

export default SidebarUser;
