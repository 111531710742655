import { gql, useQuery } from '@apollo/client';
import { Icon } from '@kinsta/icons';
import { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import {
	MakeAffiliateLinkModal,
	SidebarAffiliateAdminNav,
	SidebarAffiliateNav,
	SidebarSellerAdminNav,
	SidebarSellerNav,
	SidebarSuperadminNav,
	SidebarUser,
} from '@/src/app-specific-components';
import * as Styled from '@/src/app-specific-components/Sidebar/Sidebar.style';
import { Role } from '@/src/utilities/graphql-types.gen';

const RoleQuery = gql`
	query RoleQuery {
		user {
			id
			role
		}
	}
`;

const Sidebar = () => {
	const [isExpanded, setIsExpanded] = useState(false);
	const ref = useRef(null);
	const { data } = useQuery(RoleQuery);

	useOnClickOutside(ref, () => {
		return setIsExpanded(false);
	});

	const handleClick = () => {
		setIsExpanded(false);
	};

	return (
		<div ref={ref}>
			<Styled.HamburgerButton
				onClick={() => {
					return setIsExpanded(!isExpanded);
				}}
			>
				<Icon type='MenuUnfold' />
			</Styled.HamburgerButton>

			<Styled.Sidebar isExpanded={isExpanded}>
				<Styled.Logo>
					<Styled.LogoIcon src='https://cdn.kinsta.com/logos/kinsta-brand-icon-light.svg' />
					<Styled.LogoText>Affiliates</Styled.LogoText>
				</Styled.Logo>

				<Styled.SidebarLinks onClick={handleClick}>
					{data?.user?.role === Role.SUPERADMIN && <SidebarSuperadminNav />}
					{data?.user?.role === Role.ADMIN_AFFILIATE && (
						<SidebarAffiliateAdminNav />
					)}
					{data?.user?.role === Role.AFFILIATE && <SidebarAffiliateNav />}
					{data?.user?.role === Role.ADMIN_SELLER && <SidebarSellerAdminNav />}
					{data?.user?.role === Role.SELLER && <SidebarSellerNav />}
				</Styled.SidebarLinks>

				{data?.user?.role === Role.AFFILIATE && (
					<Styled.Bottom>
						<SidebarUser />
						<MakeAffiliateLinkModal />
					</Styled.Bottom>
				)}
			</Styled.Sidebar>
		</div>
	);
};

export default Sidebar;
