import { ReactNode } from 'react';

import animationVariants from '@/src/animations';
import * as Styled from '@/src/components/PageTitle/PageTitle.style';

type Props = {
	children: ReactNode;
	animate?: boolean;
};

const PageTitle = ({ children, animate = true }: Props) => {
	return (
		<>
			{animate ? (
				<Styled.PageTitle
					initial='initial'
					animate='default'
					exit='exit'
					variants={animationVariants.headline}
				>
					<Styled.HeadlineCentered>{children}</Styled.HeadlineCentered>
				</Styled.PageTitle>
			) : (
				<Styled.PageTitle>
					<Styled.HeadlineCentered>{children}</Styled.HeadlineCentered>
				</Styled.PageTitle>
			)}
		</>
	);
};

export default PageTitle;
