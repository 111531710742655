import { css } from '@emotion/react';
import { constants } from '@kinsta/base-style';

import reactToastify from '@/src/css-overrides/react-toastify';
import { breakpoints } from '@/src/vars';

const { color, gutter } = constants;

const baseCss = css`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html {
		background: ${color.dove};
	}

	img {
		max-width: 100%;
	}

	/* Recharts overrides */
	.recharts-xAxis text {
		fill: ${color.mediumGrey};
		font-size: 13px;
	}

	.recharts-xAxis .recharts-cartesian-axis-tick {
		transform: translateY(8px);
	}

	/* react-modal overrides */
	.ReactModal__Html--open,
	.ReactModal__Body--open {
		overflow: hidden;
	}

	.ReactModal__Overlay {
		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.45);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/* react-tabs overrides */
	.react-tabs__tab-list {
		display: flex;
		list-style: none;
		padding: 0 ${gutter * 3}px;
		align-items: flex-end;
	}

	.react-tabs__tab {
		color: ${color.mediumGrey};
		padding: ${gutter * 2}px ${gutter * 1.5}px ${gutter * 1.5}px;
		cursor: pointer;
		border-bottom-width: 2px;
		border-bottom-style: solid;
		border-bottom-color: transparent;
		transition: all .3s ease;
		white-space: pre;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		.react-tabs__tab {
			padding: ${gutter * 3}px ${gutter * 1.5}px ${gutter * 1.5}px;
		}
	}

	.react-tabs__tab--selected {
		color: ${color.anthracite};
		border-bottom-color: ${color.anthracite};
	}

	${reactToastify}

	/* Custom */
	#__copy {
		display: inline;
		width: 0;
		height: 0;
	}
`;

export default baseCss;
