import { DevTool, PLACEMENT } from '@hookform/devtools';
import { get } from 'lodash';
import {
	Children,
	cloneElement,
	FocusEventHandler,
	isValidElement,
	ReactChild,
	ReactNode,
} from 'react';
import {
	Control,
	FieldError,
	FieldValues,
	SubmitHandler,
	UseFormRegister,
	UseFormReturn,
	UseFormTrigger,
} from 'react-hook-form';

export type ClonedProps = {
	triggerValidation: UseFormTrigger<any>;
	registerFunc: UseFormRegister<any>;
	errors?: FieldError;
	isDirty: boolean;
	isValid: boolean;
	onBlur: FocusEventHandler;
	onFocus: FocusEventHandler;
	control: Control<FieldValues>;
};

type Props = {
	onSubmit?: SubmitHandler<any>;
	children: ReactChild | ReactChild[];
	noValidate?: boolean;
	form: UseFormReturn<any>;
	devTool?: boolean;
	devToolPlacement?: PLACEMENT;
};

function recursiveMap(
	children: Props['children'],
	fn: (arg: any) => JSX.Element,
): ReactNode | ReactNode[] {
	const mappedChildren = Children.map(children, (child: ReactNode) => {
		if (!isValidElement(child)) {
			return child;
		}

		if (child.props.children) {
			return fn(
				cloneElement<ReactNode | ReactNode[]>(child, {
					children: recursiveMap(child.props.children, fn),
				}),
			);
		}

		return fn(child);
	});

	return mappedChildren.length === 1 ? mappedChildren[0] : mappedChildren;
}

const Form = ({
	onSubmit,
	children,
	noValidate = true,
	form,
	devTool,
	devToolPlacement = 'bottom-left',
}: Props) => {
	const { register, handleSubmit, trigger, formState, reset, control } = form;
	const { dirtyFields, errors } = formState;

	return (
		<form
			onSubmit={onSubmit && handleSubmit(onSubmit)}
			noValidate={noValidate}
			method='post'
		>
			{recursiveMap(children, (child: JSX.Element) => {
				if (child.props.name) {
					return cloneElement(child, {
						errors: get(errors, child.props.name),
						isDirty: get(dirtyFields, child.props.name),
						isValid: !get(errors, child.props.name),
						triggerValidation: trigger,
						reset,
						registerFunc: register,
						key: child.props.name,
						control,
					});
				}

				return child;
			})}

			{process.env.NODE_ENV === 'development' && devTool && (
				<DevTool control={control} placement={devToolPlacement} />
			)}
		</form>
	);
};

export default Form;
