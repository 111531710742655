import { ReactNode } from 'react';

import * as Styled from '@/src/components/Card/CardHeader.style';

type Props = {
	children: ReactNode;
	hasBorderBottom?: boolean;
};

const CardHeader = ({ children, hasBorderBottom }: Props) => {
	return (
		<Styled.CardHeader hasBorderBottom={hasBorderBottom}>
			{children}
		</Styled.CardHeader>
	);
};

export default CardHeader;
