import { ReactNode } from 'react';

import * as Styled from '@/src/components/Timeline/TimelineItem.style';

type Props = {
	title?: string;
	date?: ReactNode;
	message?: ReactNode;
	index?: number;
	endNode: boolean;
	color?: string;
};
const { StyledLi, LogItemMain, LogItemTime } = Styled;

const TimelineItem = ({
	title,
	date,
	message,
	index,
	endNode,
	color,
}: Props) => {
	return (
		<StyledLi key={index} endNode={endNode} color={color}>
			<LogItemMain>
				<h2>{title}</h2>
				<span>
					<LogItemTime>{date}</LogItemTime>
					:&nbsp;
				</span>
			</LogItemMain>
			{message}
			<br />
		</StyledLi>
	);
};

export default TimelineItem;
