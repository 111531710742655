import { useCallback, useReducer } from 'react';

type ActionType = { type: 'UPDATE'; payload: string };
type PreLogInLanguage = string;

const usePreLogInLanguageReducer = (
	initialLanguage: PreLogInLanguage,
): {
	selectedLanguage: PreLogInLanguage;
	changeLanguage: (text: string) => void;
} => {
	const [selectedLanguage, dispatch] = useReducer(
		(state: PreLogInLanguage, action: ActionType) => {
			if (action.type === 'UPDATE') {
				return action.payload;
			}

			return state;
		},
		initialLanguage,
	);

	const changeLanguage = useCallback((payload: string) => {
		dispatch({
			type: 'UPDATE',
			payload,
		});
	}, []);

	return {
		selectedLanguage,
		changeLanguage,
	};
};

export default usePreLogInLanguageReducer;
