import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter, color, borderRadius } = constants;

type Props = {
	text?: string;
};

export const ModalTitle = styled.div<Props>(({ text }) => {
	return css(
		{
			display: 'grid',
			alignItems: 'center',
			borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
			background: color.white,
			borderBottom: `1px solid ${color.dove}`,
			padding: `${gutter * 3}px ${gutter * 4}px`,
		},
		text && {
			gridTemplateColumns: '1fr auto',
			gap: gutter * 2,
		},
	);
});

export const CloseButton = styled.button(() => {
	return css({
		display: 'grid',
		alignItems: 'center',
		cursor: 'pointer',
		color: color.mediumGrey,
		marginLeft: 'auto',
		border: 0,
		background: 'none',
		transition: 'color .3s',
		'&:hover, &:focus': { color: color.anthracite },
	});
});
