import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { darken } from 'polished';

const { color, borderRadius } = constants;

export const Code = styled.code(() => {
	return css({
		background: darken(0.025, color.dove),
		padding: '2px 4px',
		fontFamily: 'monospace',
		borderRadius: borderRadius.s,
		border: `1px solid ${darken(0.075, color.dove)}`,
	});
});
