import { Language } from '@/src/utilities/graphql-types.gen';

const localeToLanguage = {
	en: Language.ENGLISH,
	fr: Language.FRENCH,
	it: Language.ITALIAN,
	es: Language.SPANISH,
	de: Language.GERMAN,
	nl: Language.DUTCH,
	pt: Language.PORTUGUESE,
	ja: Language.JAPANESE,
};

export default localeToLanguage;
