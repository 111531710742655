import { default as NextHead } from 'next/head';
import { ReactNode } from 'react';

type Props = {
	title?: string;
	children?: ReactNode;
};

const Head = ({ title, children }: Props) => {
	const titlePrefix = title ? `${title} - ` : '';
	const baseTitle = 'Kinsta Affiliates';
	const compiledPageTitle = `${titlePrefix}${baseTitle}`;

	return (
		<NextHead>
			<title>{compiledPageTitle}</title>
			{children}
		</NextHead>
	);
};

export default Head;
