import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';

import { useAffiliateSettingsQuery } from '@/src/hooks/useLocale/useLocale.queries.gen';
import { Language } from '@/src/utilities/graphql-types.gen';

const languages = {
	[Language.FRENCH]: fr,
	[Language.GERMAN]: de,
	[Language.SPANISH]: es,
	[Language.ITALIAN]: it,
	[Language.ENGLISH]: enUS,
	[Language.DUTCH]: nl,
	[Language.PORTUGUESE]: pt,
	[Language.JAPANESE]: ja,
};

const useLocale = () => {
	const { data } = useAffiliateSettingsQuery();

	if (data?.user?.__typename !== 'Affiliate') {
		return enUS;
	}

	return languages[data.user.settings.language];
};

export default useLocale;
