import { GraphQLError } from 'graphql';

export enum ACErrorCode {
	NOT_LOGGED_IN = 'NOT_LOGGED_IN',
	INVALID_ACCESS = 'INVALID_ACCESS',
	WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
	USER_BANNED = 'USER_BANNED',
	USER_DELETED = 'USER_DELETED',
	USER_PENDING = 'USER_PENDING',
	USER_REJECTED = 'USER_REJECTED',
}

const acCodeToMessage: Record<ACErrorCode, string> = {
	[ACErrorCode.NOT_LOGGED_IN]: 'Not logged in.',
	[ACErrorCode.INVALID_ACCESS]: 'Invalid access.',
	[ACErrorCode.WRONG_CREDENTIALS]: 'Wrong Credentials',
	[ACErrorCode.USER_BANNED]: 'User is banned',
	[ACErrorCode.USER_DELETED]: 'User was deleted',
	[ACErrorCode.USER_PENDING]: 'Account pending approval',
	[ACErrorCode.USER_REJECTED]: 'Affiliate application was rejected',
};

/** Custom errors thrown to be handled by Apollo Client. */
export class ACError extends GraphQLError {
	constructor(code: ACErrorCode) {
		super(acCodeToMessage[code], { extensions: { code } });
	}
}

export enum ObjectionErrorCode {
	EMAIL_TAKEN = 'EMAIL_TAKEN',
	UNHANDLED_UNIQUE_VIOLATION_ERROR = 'UNHANDLED_UNIQUE_VIOLATION_ERROR',
}

export const objectionCodeToMessage: Record<ObjectionErrorCode, string> = {
	[ObjectionErrorCode.EMAIL_TAKEN]: 'That email address is already in use.',
	[ObjectionErrorCode.UNHANDLED_UNIQUE_VIOLATION_ERROR]:
		'Resource already exists.',
};

export class ObjectionError extends GraphQLError {
	constructor(code: ObjectionErrorCode) {
		super(objectionCodeToMessage[code], { extensions: { code } });
	}
}
