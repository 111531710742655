import { useRouter } from 'next/router';
import { useContext } from 'react';

import { LanguageContext } from '@/src/context/PreLogInLanguageContext';
import { Language } from '@/src/utilities/graphql-types.gen';
import localeToLanguage from '@/src/utilities/localeToLanguage';
import * as translations from '@/src/utilities/translations/index';

const useLocaleTranslations = () => {
	const { selectedLanguage } = useContext(LanguageContext);

	const router = useRouter();
	const { locale } = router;
	type OnlyKeys = keyof typeof localeToLanguage;

	const slicedLocale = locale?.slice(0, 2);

	const language =
		selectedLanguage.length > 0
			? (selectedLanguage as Language)
			: localeToLanguage[slicedLocale as OnlyKeys];

	return translations[language];
};

export default useLocaleTranslations;
