import { useMenuTranslationQuery } from '@/src/app-specific-components/Sidebar/Navs/SidebarAffiliateNav/SidebarAffiliateNav.queries.gen';
import SidebarLink from '@/src/app-specific-components/Sidebar/SidebarLink';

const SidebarAffiliateNav = () => {
	const { data, loading } = useMenuTranslationQuery();

	if (loading) {
		return null;
	}

	if (data?.user?.__typename !== 'Affiliate') {
		return null;
	}

	return (
		<>
			<SidebarLink
				href='/'
				iconType='MenuHome'
				text={data.user.translation.menu.dashboard}
			/>
			<SidebarLink
				href='/referrals'
				iconType='MenuUsers'
				text={data.user.translation.menu.referredCustomers}
			/>
			<SidebarLink
				href='/referred-visitors'
				iconType='MenuActivityLog'
				text={data.user.translation.menu.referredVisitors}
			/>
			<SidebarLink
				href='/payments'
				iconType='MenuBilling'
				text={data.user.translation.menu.payments}
			/>
			<SidebarLink
				href='/settings'
				iconType='MenuSettings'
				text={data.user.translation.menu.settings}
			/>
			<SidebarLink
				href='/materials'
				iconType='MenuSites'
				text={data.user.translation.menu.promoMaterial}
			/>
			<SidebarLink
				href='/resources'
				iconType='MenuMetrics'
				text={data.user.translation.menu.resources}
			/>
			<SidebarLink
				iconType='MenuLogout'
				text={data.user.translation.menu.logOut}
				isLogout
			/>
		</>
	);
};

export default SidebarAffiliateNav;
