import { ReactNode } from 'react';

import * as Styled from '@/src/components/Modal/ModalContent.style';

type Props = {
	children: ReactNode;
};

const ModalContent = ({ children }: Props) => {
	return <Styled.ModalContent>{children}</Styled.ModalContent>;
};

export default ModalContent;
