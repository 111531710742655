import { typography } from '@kinsta/base-style';
import { Icon } from '@kinsta/icons';
import { startCase } from 'lodash';
import { ReactNode } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import ReactSelect, {
	ActionMeta,
	components,
	IndicatorProps,
	MenuPlacement,
	OptionTypeBase,
} from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';

import { Arrow } from '@/src/components';
import { ClonedProps } from '@/src/components/Form';
import reactSelectStyleOverrides from '@/src/components/Select/reactSelectStyleOverrides';
import * as Styled from '@/src/components/Select/Select.style';
import { Language } from '@/src/utilities/graphql-types.gen';
import * as translations from '@/src/utilities/translations';

const errorTranslations = translations[Language.ENGLISH].errorTranslations;
type ErrorTranslationTypes = typeof errorTranslations;

const { Label } = typography;

type SelectOption = {
	label: string;
	value: string;
	disabled?: boolean;
};

type Props = {
	name: string;
	label?: string;
	onChange?: (value: any, actionMeta?: ActionMeta<any>) => void;
	placeholder?: string;
	helper?: string;
	defaultValue?:
		| SelectOption['value']
		| string[]
		| { label: string; value: string };
	disabled?: boolean;
	required?: boolean;
	register?: RegisterOptions;
	options: SelectOption[];
	isSearchable?: boolean;
	hideLabel?: boolean;
	menuPlacement?: MenuPlacement;
	isMulti?: boolean;
	width?: string;
	marginRight?: string;
	reactIcon?: ReactNode;
	errorTranslations?: ErrorTranslationTypes;
};

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
	const { isFocused, hasValue, isMulti } = props;
	const { menuIsOpen, isSearchable } = props.selectProps;

	const arrowDirection = menuIsOpen ? 'up' : 'down';
	if (isMulti && hasValue) {
		return null;
	}

	return (
		<components.DropdownIndicator {...props}>
			{isSearchable && isFocused ? (
				<Icon type='Search' />
			) : (
				<Arrow direction={arrowDirection} size={16} />
			)}
		</components.DropdownIndicator>
	);
};

const NoOptionsMessage = (props: NoticeProps<OptionTypeBase, any>) => {
	return (
		<components.NoOptionsMessage {...props}>
			<Styled.NoResults>
				<img src='/img/no-results.svg' alt='No results' />
				<Label>No Results</Label>
			</Styled.NoResults>
		</components.NoOptionsMessage>
	);
};

const Select = ({
	name,
	label = startCase(name),
	placeholder = 'Select...',
	defaultValue,
	disabled,
	required,
	register = {},
	errors,
	isValid,
	helper,
	options,
	control,
	isSearchable = true,
	hideLabel = false,
	onChange,
	menuPlacement = 'auto',
	isMulti = false,
	width = '',
	marginRight = '',
	reactIcon,
	errorTranslations,
}: Props & ClonedProps) => {
	// Turn HTML attributes into validation messages
	const requiredRegister = required
		? { required: errorTranslations?.fieldRequired ?? 'This field is required' }
		: null;

	const propsToRegister = [requiredRegister];
	// register last so it overwrites others for custom messages
	const registerMerged: RegisterOptions = Object.assign(
		{},
		...propsToRegister,
		register,
	);

	const Control = ({ children, ...props }: any) => {
		if (reactIcon) {
			return (
				<components.Control {...props}>
					<span
						style={{
							marginLeft: '9.5%',
							marginTop: '3%',
						}}
					>
						{reactIcon}
					</span>
					{children}
				</components.Control>
			);
		}

		return <components.Control {...props}>{children}</components.Control>;
	};

	const _defaultValue = () => {
		if (typeof defaultValue === 'string') {
			return options.find((option) => {
				return option.value === defaultValue;
			});
		} else if (Array.isArray(defaultValue)) {
			return defaultValue.map((value) => {
				return options.find((option) => {
					return option.value === value;
				});
			});
		}

		const selection = options.find((option) => {
			return option.value === defaultValue?.value;
		});

		if (selection?.label && defaultValue?.label) {
			selection.label = defaultValue.label;
		}

		return selection;
	};

	return (
		<div
			style={{
				position: 'relative',
				width: width ?? '100%',
				marginRight: marginRight ?? '0',
			}}
		>
			{!hideLabel && (
				<Styled.FormLabel htmlFor={`input-${name}`}>{label}</Styled.FormLabel>
			)}
			{helper && <Styled.Helper>{helper}</Styled.Helper>}
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue}
				rules={registerMerged}
				render={({ field: { onBlur, name, ref, onChange: rhfOnChange } }) => {
					return (
						<ReactSelect
							onBlur={onBlur}
							onChange={(e) => {
								let eventValue;
								if (isMulti) {
									eventValue = e?.map((option: SelectOption) => {
										return option.value;
									});
								} else if (e && 'value' in e) {
									eventValue = e.value;
								}
								onChange?.(eventValue);
								rhfOnChange(eventValue);
							}}
							defaultValue={_defaultValue()}
							ref={ref}
							name={name}
							options={options}
							inputId={`input-${name}`}
							placeholder={placeholder}
							isDisabled={disabled}
							required={required}
							styles={reactSelectStyleOverrides(isValid)}
							components={{
								DropdownIndicator,
								NoOptionsMessage,
								Control,
							}}
							isSearchable={isSearchable}
							menuPlacement={menuPlacement}
							isMulti={isMulti}
						/>
					);
				}}
			/>

			{errors?.types && (
				<Styled.ErrorList>
					{Object.values(errors.types).map((message) => {
						return <Styled.Error key={`${message}`}>{message}</Styled.Error>;
					})}
				</Styled.ErrorList>
			)}
		</div>
	);
};

const SelectWithoutClonedProps = (props: Props) => {
	return <Select {...(props as Props & ClonedProps)} />;
};

SelectWithoutClonedProps.displayName = 'Select';

export default SelectWithoutClonedProps;
