import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { MKColumn } from '../Table';
import * as Styled from './ColumnFilterMenu.style';
import { Checkbox } from '@kinsta/choice';
import { Button } from '@/src/components';
import { DropdownContext } from '@kinsta/dropdown';

type Props = {
	columns: MKColumn[];
	hiddenColumns: string[];
	setHiddenColumns: Dispatch<SetStateAction<string[]>>;
};

const ColumnFilterMenu = ({
	columns,
	hiddenColumns,
	setHiddenColumns,
}: Props) => {
	const { setOpen } = useContext(DropdownContext);
	const [hasChanges, setHasChanges] = useState(false);
	const [checkedItems, setCheckedItems] = useState<boolean[]>(
		columns.map(({ dataIndex }) => !hiddenColumns.includes(dataIndex)),
	);

	return (
		<Styled.List>
			<Styled.HeaderText>Show/hide columns</Styled.HeaderText>
			{columns.map((column, index) => (
				<Checkbox
					key={column.dataIndex}
					label={column.title}
					isChecked={checkedItems[index]}
					isDisabled={
						checkedItems[index] &&
						checkedItems.filter((checked) => checked).length === 1
					}
					onClick={() => {
						setHasChanges(true);
						setCheckedItems((prev) => {
							return [
								...prev.slice(0, index),
								!prev[index],
								...prev.slice(index + 1),
							];
						});
					}}
				/>
			))}
			<Button
				onClick={() => {
					setHiddenColumns(
						columns
							.filter((column, index) => {
								return !checkedItems[index];
							})
							.map((column) => {
								return column.dataIndex;
							}),
					);
					setHasChanges(false);
					setOpen(false);
				}}
				isDisabled={!hasChanges}
			>
				Apply changes
			</Button>
			<Button
				type='secondary'
				onClick={() => {
					setCheckedItems(() => columns.map(() => true));
					setHiddenColumns([]);
					setHasChanges(false);
					setOpen(false);
				}}
				isDisabled={hiddenColumns.length === 0}
			>
				Reset to default
			</Button>
		</Styled.List>
	);
};

export default ColumnFilterMenu;
