import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

import { breakpoints } from '@/src/vars';

const { gutter, color } = constants;

type NavLinkProps = {
	active: boolean;
};

export const TwoColumnLayout = styled.main(() => {
	return css({
		display: 'grid',
		gap: gutter,
		gridTemplateRows: 'auto auto',
		[`@media (min-width: ${breakpoints.desktop}px)`]: {
			gap: gutter * 3,
			gridTemplateColumns: '140px 1fr',
		},
	});
});

export const NavLinks = styled.ul(() => {
	return css({
		listStyle: 'none',
		marginTop: -gutter,
		display: 'flex',
		gap: gutter * 3,
		alignItems: 'flex-end',
		[`@media (min-width: ${breakpoints.desktop}px)`]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			gap: 0,
		},
	});
});

export const Li = styled.li(() => {
	return css({ width: '100%' });
});

export const NavLink = styled.a(({ active }: NavLinkProps) => {
	return css({
		color: active ? color.anthracite : color.mediumGrey,
		fontWeight: active ? 'bold' : 'normal',
		textDecoration: 'none',
		padding: `${gutter * 2}px 0`,
		display: 'flex',
		transition: 'color .3s ease',
		'&:hover': { color: color.anthracite },
	});
});

export const Box = styled.div(() => {
	return css({ padding: gutter * 3 });
});
