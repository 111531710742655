import { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';

import { DisplayError } from '@/src/components';

export type Variant = 'page' | 'component';

type Props = {
	resetFunction?: () => void;
	refreshPage?: boolean;
	variant?: Variant;
};

type State = {
	error: Error | null;
	errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			error: null,
			errorInfo: null,
		};
		this.clearError = this.clearError.bind(this);
	}

	static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
		return {
			error,
			errorInfo,
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		Sentry.captureException(error);

		this.setState({
			error,
			errorInfo,
		});
	}

	clearError() {
		this.props.resetFunction?.();
		this.setState({
			error: null,
			errorInfo: null,
		});
	}

	render() {
		const { resetFunction } = this.props;

		if (this.state.error) {
			return (
				<DisplayError
					variant={this.props.variant}
					message={this.state.error.message}
					resetFunction={resetFunction && this.clearError}
					refreshPage={this.props.refreshPage}
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
