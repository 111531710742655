import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';

const { gutter, color } = constants;
const { cardTitle } = typography;

type Props = {
	hasBorderBottom?: boolean;
};

export const CardHeader = styled.header(({ hasBorderBottom }: Props) => {
	return css(
		{
			padding: `${gutter * 2}px ${gutter * 3}px`,
			borderBottom: hasBorderBottom ? `1px solid ${color.dove}` : 'none',
		},
		cardTitle,
	);
});
