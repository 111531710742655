import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { Icon } from '@kinsta/icons';

const { borderRadius, color, gutter } = constants;

export const ChartTooltip = styled.div(() => {
	return css({
		borderRadius: borderRadius.s,
		background: color.anthracite,
		color: color.white,
		padding: `${gutter * 1.5}px ${gutter * 2}px`,
		display: 'grid',
		gap: gutter / 2,
	});
});

export const UpIcon = styled(Icon)(() => {
	return css({
		color: color.green,
		transform: 'rotate(-45deg)',
	});
});

export const DownIcon = styled(Icon)(() => {
	return css({
		color: color.red,
		transform: 'rotate(135deg)',
	});
});
