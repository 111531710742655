import { CSSProperties, ReactNode } from 'react';

import * as Styled from '@/src/components/Card/Card.style';

type Props = {
	children: ReactNode;
	isCenteredContent?: boolean;
	isPadded?: boolean;
	style?: CSSProperties;
};

const Card = ({ children, isCenteredContent, isPadded, style }: Props) => {
	return (
		<Styled.Card
			isCenteredContent={isCenteredContent}
			isPadded={isPadded}
			style={style}
		>
			{children}
		</Styled.Card>
	);
};

export default Card;
