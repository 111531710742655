import { ReactNode } from 'react';

import * as Styled from '@/src/components/Code/Code.style';

type Props = {
	children: ReactNode;
};

const Code = ({ children }: Props) => {
	return <Styled.Code>{children}</Styled.Code>;
};

export default Code;
