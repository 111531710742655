import { ReactNode } from 'react';

import * as Styled from '@/src/components/Pre/Pre.style';

type Props = {
	children: ReactNode;
};

const Pre = ({ children }: Props) => {
	return (
		<Styled.PreWrap>
			<Styled.Pre>{children}</Styled.Pre>
		</Styled.PreWrap>
	);
};

export default Pre;
