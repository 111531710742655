import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';

const { borderRadius, gutter } = constants;

export const ErrorList = styled.ul(() => {
	return css({ listStyle: 'none' });
});

export const Error = styled.li(() => {
	return css({ ...typography.error });
});

export const FormLabel = styled.label(() => {
	return css({ ...typography.formLabel });
});

export const Helper = styled.span(() => {
	return css({ ...typography.description });
});

export const NoResults = styled.div(() => {
	return css({
		display: 'grid',
		gridTemplateRows: 'auto auto',
		gap: gutter,
		justifyContent: 'center',
		borderRadius: borderRadius.s,
		padding: `${gutter * 3}px ${gutter * 2}px`,
		textAlign: 'center',
	});
});
