import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#__next');
ReactModal.defaultStyles = { overlay: { zIndex: 700 } };

const { borderRadius, shadow } = constants;

type Props = {
	width: number;
};

export const Modal = styled(ReactModal)<Props>(({ width }) => {
	return css({
		width,
		maxWidth: 'calc(100vw - 2rem)',
		maxHeight: 'calc(100vh - 2rem)',
		overflowY: 'auto',
		position: 'relative',
		outline: 'none',
		borderRadius: borderRadius.s,
		boxShadow: shadow.xLarge,
	});
});
