import { DropdownContext } from '@kinsta/dropdown';
import { useContext } from 'react';

import * as Styled from './ColumnFilterButton.style';

type Props = {
	hiddenColumns: string[];
};

const ColumnFilterButton = ({ hiddenColumns }: Props) => {
	const { open } = useContext(DropdownContext);

	return (
		<Styled.Button isOpen={open} isFiltering={Boolean(hiddenColumns.length)}>
			<Styled.LabelIcon />
			<Styled.DropdownIcon type='MenuChevron' />
		</Styled.Button>
	);
};

export default ColumnFilterButton;
