import { Icon } from '@kinsta/icons';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { Form, Select } from '@/src/components';
import { LanguageContext } from '@/src/context/PreLogInLanguageContext';
import { useForm, useLocaleTranslations } from '@/src/hooks';
import { Language } from '@/src/utilities/graphql-types.gen';
import languageOptions from '@/src/utilities/languageOptions';
import languageToLocale from '@/src/utilities/languageToLocale';
import localeToLanguage from '@/src/utilities/localeToLanguage';

const LanguageSwitcher = () => {
	const router = useRouter();

	const { changeLanguage, selectedLanguage } = useContext(LanguageContext);

	const locale = router?.locale?.slice(0, 2) ?? 'en';
	const errorTranslations = useLocaleTranslations().errorTranslations;

	type LanguageOnlyKeys = keyof typeof localeToLanguage;
	type LocaleOnlyKeys = keyof typeof languageToLocale;

	const defaultLanguage =
		selectedLanguage.length > 0
			? selectedLanguage
			: localeToLanguage[locale as LanguageOnlyKeys];

	const form = useForm({
		defaultValues: { languageSwitcher: defaultLanguage },
	});

	const { watch } = form;
	const { languageSwitcher } = watch();

	useEffect(() => {
		changeLanguage(languageSwitcher);

		// eslint-disable-next-line
	}, [languageSwitcher]);

	useEffect(() => {
		const isEnglish = selectedLanguage === Language.ENGLISH;

		if (!isEnglish && !router.pathname.includes('404')) {
			router.replace(router.asPath, router.asPath, {
				locale: languageToLocale[selectedLanguage as LocaleOnlyKeys],
			});
		}

		// eslint-disable-next-line
	}, [selectedLanguage]);

	return (
		<div
			style={{
				position: 'absolute',
				width: '100vw',
				display: 'flex',
				justifyContent: 'flex-end',
			}}
		>
			<div
				style={{
					minWidth: '150px',
					marginRight: '0.5%',
					marginTop: '0.5%',
				}}
			>
				<Form form={form}>
					<Select
						errorTranslations={errorTranslations}
						reactIcon={<Icon type='LanguageStroke' />}
						isSearchable={false}
						width='100%'
						hideLabel={true}
						name='languageSwitcher'
						defaultValue={defaultLanguage}
						options={languageOptions}
					/>
				</Form>
			</div>
		</div>
	);
};

export default LanguageSwitcher;
