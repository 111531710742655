import { css } from '@emotion/react';
import styled from '@emotion/styled';

type LiProps = {
	color?: string;
	endNode: boolean;
};

export const StyledLi = styled.li<LiProps>(({ color, endNode }) => {
	return css({
		position: 'relative',
		marginLeft: '-1em',
		paddingBottom: '1em',
		paddingLeft: '2.5em',
		'&:before': {
			content: '""',
			backgroundColor: '#e8e8e8',
			position: 'absolute',
			bottom: endNode ? '100%' : 0,
			top: 0,
			left: '6px',
			width: '3px',
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			left: 0,
			top: 0,
			height: '12px',
			width: '12px',
			borderRadius: '100%',
			border: `2px solid ${color ?? '#5333ed'}`,
			backgroundColor: 'white',
		},
	});
});

export const LogItemMain = styled.div(() => {
	return css({
		display: 'flex',
		flexFlow: 'row-reverse',
		justifyContent: 'flex-end',
		h2: {
			fontWeight: 'normal',
			fontSize: '1rem',
			marginTop: '-0.2em',
		},
		span: { marginTop: '-0.2em' },
	});
});

export const LogItemTime = styled.time(() => {
	return css({ fontWeight: 'bold' });
});
