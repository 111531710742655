import { ReactNode, useState } from 'react';
import { Tooltip } from '@kinsta/tooltip';
import CopyIcon from '@/src/components/Copy/CopyIcon';
import { useLocaleTranslations } from '@/src/hooks';
import { Icon } from '@kinsta/icons';

type Props = {
	text: string;
	icon?: boolean;
	children?: ReactNode;
};

const TWO_SECONDS_IN_MILLISECONDS = 2000;

const Copy = ({ children, text, icon = true }: Props) => {
	const translations = useLocaleTranslations();

	const [isCopied, setIsCopied] = useState(false);

	const handleClick = () => {
		const fauxInput = document.querySelector('#__copy');

		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, TWO_SECONDS_IN_MILLISECONDS);

		if (fauxInput) {
			fauxInput.innerHTML = `<input type="text" id="__copy-input" value="${text}" />`;
			const copyableEl = document.querySelector(
				'#__copy-input',
			) as HTMLInputElement;
			copyableEl.select();
			document.execCommand('copy');
			fauxInput.innerHTML = '';
		}
	};

	if (icon) {
		return (
			<Tooltip
				content={
					isCopied
						? translations.copiedToClipboard
						: translations.copyToClipboard
				}
			>
				<span>
					{isCopied ? (
						<Icon type='Check' />
					) : (
						<CopyIcon onClick={handleClick} />
					)}
				</span>
			</Tooltip>
		);
	}

	return <div onClick={handleClick}>{children}</div>;
};

export default Copy;
