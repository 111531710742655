import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { color, gutter } = constants;

export const ModalContent = styled.div(() => {
	return css({
		background: color.white,
		padding: `${gutter * 3}px ${gutter * 4}px`,
	});
});
