import { constants } from '@kinsta/base-style';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import { ChartDot, ChartTooltip, ChartXTick } from '@/src/components';
import * as Styled from '@/src/components/Chart/Chart.style';
import { zIndexes } from '@/src/vars';

const { color } = constants;

type ChartData = {
	date: string;
	value: number;
	percentChange?: number;
};

type Props = {
	data: ChartData[];
	total?: string | number;
	dateFormat?: string;
	dayTranslations?: { [key: string]: string };
};

type XTickProps = {
	x: number;
	y: number;
	index: number;
	payload: {
		value?: string;
	};
};

const minWidth = 1000;
const height = 192;

const Chart = ({
	data,
	total,
	dateFormat = 'HH:mm',
	dayTranslations,
}: Props) => {
	const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();

	// We provide dots on the ends of the dataset then hide them in CSS so the
	// graph looks like it's starting/ending outside of its container.
	const dataWithEndcaps = [
		{ value: data[0].value },
		...data,
		{ value: data[data.length - 1].value },
	];

	return (
		<Styled.Chart>
			<Styled.Total>{total}</Styled.Total>

			<Styled.Wrap>
				<Styled.ScrollWrapper>
					<ResponsiveContainer minWidth={minWidth} width='100%' height={height}>
						<AreaChart
							data={dataWithEndcaps}
							height={height}
							onMouseLeave={() => {
								return setHoveredIndex(undefined);
							}}
							style={{ overflow: 'hidden' }}
						>
							<foreignObject x='0' y='137' height='20' width='100%'>
								<svg height={20} width='100%'>
									<rect height={20} width='100%' fill={color.dove}></rect>
								</svg>
							</foreignObject>

							<Area
								isAnimationActive={false}
								dataKey='value'
								fill={color.dove}
								fillOpacity={1}
								strokeWidth='5'
								stroke={color.purple}
								dot={ChartDot}
								activeDot={({ cx, cy, index }) => {
									setHoveredIndex(index);

									return <ChartDot cx={cx} cy={cy} active={true} />;
								}}
								type='monotone'
							/>

							<XAxis
								interval={0}
								dataKey='date'
								axisLine={false}
								tickLine={false}
								tick={({ x, y, index, payload }: XTickProps) => {
									if (!payload.value) {
										return <></>;
									}
									const date = parseISO(payload.value);
									let formattedDate = format(date, dateFormat);
									if (dayTranslations) {
										const lowerCaseDay = formattedDate.toLowerCase();

										formattedDate = dayTranslations[lowerCaseDay];
									}

									return (
										<ChartXTick
											x={x}
											y={y}
											index={index}
											hoveredIndex={hoveredIndex}
											date={formattedDate}
										/>
									);
								}}
							/>

							<YAxis
								padding={{ bottom: 20 }}
								hide={true}
								axisLine={false}
								tickLine={false}
							/>

							<Tooltip
								content={ChartTooltip as any}
								cursor={false}
								isAnimationActive={false}
								wrapperStyle={{ zIndex: zIndexes.xxs }}
							/>
						</AreaChart>
					</ResponsiveContainer>
				</Styled.ScrollWrapper>
			</Styled.Wrap>
		</Styled.Chart>
	);
};

export default Chart;
