import { ReactNode } from 'react';

import * as Styled from '@/src/components/Modal/ModalBottom.style';

type Props = {
	children: ReactNode;
};

const ModalBottom = ({ children }: Props) => {
	return <Styled.ModalBottom>{children}</Styled.ModalBottom>;
};

export default ModalBottom;
