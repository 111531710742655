/* eslint-disable */
import * as Types from '../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type SidebarUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SidebarUserQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', kaid: string, email: string, id: string, firstName: string, translation: { __typename?: 'Translation', menu: { __typename?: 'MenuTranslation', hello: string, makeLink: string } } } | { __typename?: 'BareUser', id: string, firstName?: string | null } | { __typename?: 'Seller', id: string, firstName: string } | null };


export const SidebarUserDocument = gql`
    query SidebarUser {
  user {
    id
    firstName
    ... on Affiliate {
      kaid
      email
      translation {
        menu {
          hello
          makeLink
        }
      }
    }
  }
}
    `;

/**
 * __useSidebarUserQuery__
 *
 * To run a query within a React component, call `useSidebarUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebarUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SidebarUserQuery, SidebarUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SidebarUserQuery, SidebarUserQueryVariables>(SidebarUserDocument, options);
      }
export function useSidebarUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SidebarUserQuery, SidebarUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SidebarUserQuery, SidebarUserQueryVariables>(SidebarUserDocument, options);
        }
export type SidebarUserQueryHookResult = ReturnType<typeof useSidebarUserQuery>;
export type SidebarUserLazyQueryHookResult = ReturnType<typeof useSidebarUserLazyQuery>;
export type SidebarUserQueryResult = Apollo.QueryResult<SidebarUserQuery, SidebarUserQueryVariables>;