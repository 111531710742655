import { Input as StratusInput } from '@kinsta/input';
import { startCase } from 'lodash';
import { ChangeEvent, ChangeEventHandler, FocusEvent, ReactNode } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { ClonedProps } from '@/src/components/Form';
import * as Styled from '@/src/components/Input/Input.style';
import { Language } from '@/src/utilities/graphql-types.gen';
import * as translations from '@/src/utilities/translations';

type FieldType = 'text' | 'password' | 'email' | 'number';
const errorTranslations = translations[Language.ENGLISH].errorTranslations;
type ErrorTranslationTypes = typeof errorTranslations;

type Props = {
	name: string;
	label?: string;
	onChange?: ChangeEventHandler;
	type?: FieldType;
	placeholder?: string;
	minlength?: number;
	pattern?: RegExp;
	helper?: string;
	defaultValue?: any;
	disabled?: boolean;
	required?: boolean;
	maxlength?: number;
	register?: RegisterOptions;
	onChangeValidate?: boolean;
	onBlurValidate?: boolean;
	hideLabel?: boolean;
	width?: number | string;
	autocomplete?: string;
	errorTranslations?: ErrorTranslationTypes;
	suffixItem?: ReactNode;
};

const Input = ({
	name,
	label = startCase(name),
	type = 'text',
	placeholder,
	onChange,
	defaultValue,
	maxlength,
	minlength,
	disabled,
	required,
	pattern,
	onChangeValidate = false,
	onBlurValidate = false,
	register = {},
	registerFunc,
	onBlur,
	onFocus,
	errors,
	isValid,
	triggerValidation,
	helper,
	hideLabel,
	width,
	suffixItem,
	autocomplete,
	errorTranslations,
}: Props & ClonedProps) => {
	// Turn HTML attributes into validation messages
	const requiredRegister = required
		? { required: errorTranslations?.fieldRequired ?? 'This field is required' }
		: null;
	const maxLengthRegister = maxlength
		? {
				maxLength: {
					value: maxlength,
					message: errorTranslations?.cannotBeLongerThan
						? `${errorTranslations?.cannotBeLongerThan} ${maxlength} ${errorTranslations?.characters}`
						: `Cannot be longer than ${maxlength} characters`,
				},
		  }
		: null;
	const minLengthRegister = minlength
		? {
				minLength: {
					value: minlength,
					message: errorTranslations?.mustBeAtLeast
						? `${errorTranslations?.mustBeAtLeast} ${minlength} ${errorTranslations?.charactersLong}`
						: `Must be at least ${minlength} characters long`,
				},
		  }
		: null;
	const patternRegister = pattern
		? {
				pattern: {
					value: pattern,
					message:
						errorTranslations?.correctInformation ??
						"Please make sure you've entered the correct information",
				},
		  }
		: null;

	const propsToRegister = [
		requiredRegister,
		minLengthRegister,
		maxLengthRegister,
		patternRegister,
	];
	// register last so it overwrites others for custom messages
	const registerMerged: RegisterOptions = Object.assign(
		{},
		...propsToRegister,
		register,
	);
	const registeredInput = registerFunc(name, registerMerged);

	const handleOnChange = onChangeValidate
		? async (event: ChangeEvent) => {
				registeredInput.onChange(event);
				await triggerValidation(name); // Must be called after react-hook-form's onChange
				onChange?.(event);
		  }
		: (event: ChangeEvent) => {
				onBlurValidate || registeredInput.onChange(event);
				onChange?.(event);
		  };

	const handleOnBlur = onBlurValidate
		? async (event: FocusEvent) => {
				registeredInput.onBlur(event);
				await triggerValidation(name); // Must be called after react-hook-form's onBlur
				onBlur?.(event);
		  }
		: (event: FocusEvent) => {
				onChangeValidate || registeredInput.onBlur(event);
				onBlur?.(event);
		  };

	return (
		<div style={{ width }}>
			{!hideLabel && (
				<Styled.FormLabel htmlFor={`input-${name}`}>{label}</Styled.FormLabel>
			)}
			{helper && (
				<Styled.Helper htmlFor={`input-${name}`}>{helper}</Styled.Helper>
			)}
			<StratusInput
				ref={registeredInput.ref}
				type={type}
				id={`input-${name}`}
				name={name}
				placeholder={placeholder}
				defaultValue={defaultValue}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				onFocus={onFocus}
				isDisabled={disabled}
				maxLength={maxlength}
				required={required}
				hasError={!isValid}
				width={width}
				suffixItem={suffixItem}
				autoComplete={autocomplete}
			/>
			{errors?.types && (
				<Styled.ErrorList>
					{Object.values(errors.types).map((message) => {
						return <Styled.Error key={`${message}`}>{message}</Styled.Error>;
					})}
				</Styled.ErrorList>
			)}
		</div>
	);
};

const InputWithoutClonedProps = (props: Props) => {
	return <Input {...(props as Props & ClonedProps)} />;
};

export default InputWithoutClonedProps;
