import { createContext } from 'react';

import usePreLogInLanguageReducer from '@/src/context/reducers/usePreLogInLanguageReducer';

const initialLanguage = '';

interface ContextProps {
	selectLanguage: string;
}

type usePreLogInLanguageReducerResult = ReturnType<
	typeof usePreLogInLanguageReducer
>;

export const LanguageContext = createContext<usePreLogInLanguageReducerResult>({
	selectedLanguage: initialLanguage,
	changeLanguage: () => {
		return undefined;
	},
});
// eslint-disable-next-line
const PreLogInLanguageContext: React.FunctionComponent<ContextProps> = ({
	selectLanguage,
	children,
}) => {
	const { selectedLanguage, changeLanguage } =
		usePreLogInLanguageReducer(selectLanguage);

	return (
		<LanguageContext.Provider
			value={{
				selectedLanguage,
				changeLanguage,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

export default PreLogInLanguageContext;
