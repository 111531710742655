import { constants } from '@kinsta/base-style';
import { Icon } from '@kinsta/icons';

import * as Styled from '@/src/components/PageLoading/PageLoading.style';

const { color } = constants;

const PageLoading = () => {
	return (
		<Styled.PageLoading>
			<Icon
				type='Loading'
				style={{
					width: 32,
					height: 32,
				}}
				color={color.purple}
			/>
		</Styled.PageLoading>
	);
};

export default PageLoading;
