import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { Icon } from '@kinsta/icons';
import CogIcon from './CogIcon';

export const Button = styled.button<{ isOpen: boolean; isFiltering: boolean }>(
	({ isOpen, isFiltering }) => {
		const getBackgroundColor = () => {
			if (isFiltering && isOpen) {
				return constants.color.deepPurple;
			}

			if (isFiltering) {
				return constants.color.purple;
			}

			if (isOpen) {
				return constants.color.white;
			}

			return undefined;
		};

		const getTextColor = () => {
			if (isFiltering) {
				return constants.color.white;
			}

			if (isOpen) {
				return constants.color.slateGrey;
			}

			return constants.color.mediumGrey;
		};

		return css({
			all: 'unset',
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: constants.color.lightGrey,
			borderRadius: constants.borderRadius.s,
			padding: `${constants.gutter / 2}px ${constants.gutter}px`,
			lineHeight: 1,
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			backgroundColor: getBackgroundColor(),
			color: getTextColor(),
			transition: 'background-color 0.1s, color 0.1s',
			':hover': {
				backgroundColor: isFiltering
					? constants.color.deepPurple
					: constants.color.white,
				color: isFiltering ? constants.color.white : constants.color.slateGrey,
			},
		});
	},
);

export const LabelIcon = styled(CogIcon)(() => {
	return css({
		width: 18,
		height: 18,
	});
});

export const DropdownIcon = styled(Icon)(() => {
	return css({
		width: 22,
		height: 22,
	});
});
