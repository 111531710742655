import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { breakpoints, miscStyles } from '@/src/vars';

export const PageLoading = styled.div(() => {
	return css({
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			width: `calc(100vw - ${miscStyles.desktopSidebarWidth}px)`,
		},
	});
});
