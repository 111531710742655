import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';

import { breakpoints, miscStyles, zIndexes } from '@/src/vars';

const { gutter, color } = constants;
const { Headline } = typography;

export const PageTitle = styled(motion.header)(() => {
	return css({
		position: 'sticky',
		top: 0,
		left: 0,
		width: '100%',
		zIndex: zIndexes.xl,
		background: transparentize(0.1, color.dove),
		padding: `0 ${gutter * 3}px`,
		height: gutter * 8,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		backdropFilter: 'blur(3px)',
		textAlign: 'right',
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			height: gutter * 10,
			justifyContent: 'flex-start',
			padding: `0 ${gutter * 4}px`,
			textAlign: 'left',
			left: miscStyles.desktopSidebarWidth,
		},
	});
});

export const HeadlineCentered = styled(Headline)(() => {
	return css({
		display: 'flex',
		alignItems: 'center',
		gap: gutter * 2,
		width: '100%',
	});
});
