import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';
import TextareaAutosize from 'react-autosize-textarea';

import { breakpoints } from '@/src/vars';

const { gutter, color, borderRadius, fontFamily } = constants;

type TextareaProps = {
	hasError?: boolean;
	hasAutoGrow?: boolean;
	minRows: number;
	width?: string | number;
};

const textareaStyles = ({
	hasError,
	hasAutoGrow,
	minRows,
	width,
}: TextareaProps) => {
	return css({
		color: color.anthracite,
		fontSize: 14,
		fontFamily: fontFamily.brandon,
		lineHeight: 1.5,
		fontWeight: 400,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: hasError ? color.red : color.lightGrey,
		borderRadius: borderRadius.s,
		outline: 'none',
		padding: `${gutter}px ${gutter * 2}px`,
		resize: 'vertical',
		width,
		'&:focus, &:active, &:hover': {
			borderColor: hasError ? color.red : color.purple,
		},
		/* eslint-disable no-nested-ternary */
		height: hasAutoGrow ? 'auto' : 28 * minRows > 36 ? 28 * minRows : 36,
		minHeight: 28 * minRows > 36 ? 28 * minRows : 36,
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			height: hasAutoGrow ? 'auto' : 28 * minRows > 40 ? 28 * minRows : 40,
			minHeight: 28 * minRows > 40 ? 28 * minRows : 40,
		},
		/* eslint-enable no-nested-ternary */
	});
};

export const TextareaWrap = styled.div(() => {
	return css({
		display: 'flex',
		flexDirection: 'column',
		gap: gutter,
	});
});

export const AutoSizeTextarea = styled(TextareaAutosize, {
	shouldForwardProp: (prop) => {
		return isPropValid(prop);
	},
})(({ hasError, hasAutoGrow, minRows, width }: TextareaProps) => {
	return textareaStyles({
		hasError,
		hasAutoGrow,
		minRows,
		width,
	});
});

export const TextArea = styled.textarea(
	({ hasError, hasAutoGrow, minRows, width }: TextareaProps) => {
		return textareaStyles({
			hasError,
			hasAutoGrow,
			minRows,
			width,
		});
	},
);

export const ErrorList = styled.ul(() => {
	return css({ listStyle: 'none' });
});

export const Error = styled.li(() => {
	return css({ ...typography.error });
});

export const FormLabel = styled.label(() => {
	return css({ ...typography.formLabel });
});

export const Helper = styled.span(() => {
	return css({ ...typography.description });
});
