import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';

const { SmallText } = typography;

export const List = styled.ul(() => {
	return css({
		background: constants.color.white,
		listStyle: 'none',
		display: 'flex',
		flexDirection: 'column',
		gap: constants.gutter * 2,
		alignItems: 'stretch',
		padding: constants.gutter * 2,
		boxShadow: constants.shadow.large,
	});
});

export const Row = styled.div(() => {
	return css({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	});
});

export const HeaderText = styled(SmallText)(() => {
	return css({
		color: constants.color.anthracite,
		fontWeight: constants.fontWeight.semiBold,
	});
});
