import { Tooltip } from '@kinsta/tooltip';

type Props = {
	content: string;
	width: number;
	marginRight?: number;
};

const ShortenUUID = ({ content, width, marginRight }: Props) => {
	return (
		<Tooltip content={content}>
			<div
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					width: `${width}rem`,
					marginRight: `${marginRight}rem`,
				}}
			>
				{content}
			</div>
		</Tooltip>
	);
};

export default ShortenUUID;
