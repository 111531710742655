import { startCase } from 'lodash';
import { ChangeEvent, ChangeEventHandler, FocusEvent } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { ClonedProps } from '@/src/components/Form';
import * as Styled from '@/src/components/Textarea/Textarea.style';
import { Language } from '@/src/utilities/graphql-types.gen';
import * as translations from '@/src/utilities/translations';

const errorTranslations = translations[Language.ENGLISH].errorTranslations;
type ErrorTranslationTypes = typeof errorTranslations;

type Props = {
	name: string;
	label?: string;
	onChange?: ChangeEventHandler;
	placeholder?: string;
	minlength?: number;
	pattern?: RegExp;
	helper?: string;
	defaultValue?: any;
	disabled?: boolean;
	required?: boolean;
	maxlength?: number;
	register?: RegisterOptions;
	onChangeValidate?: boolean;
	onBlurValidate?: boolean;
	hasAutoGrow?: boolean;
	rows?: number;
	hideLabel?: boolean;
	width?: string | number;
	errorTranslations?: ErrorTranslationTypes;
};

const Textarea = ({
	name,
	label = startCase(name),
	placeholder,
	onChange,
	defaultValue,
	maxlength,
	minlength,
	disabled,
	required,
	pattern,
	onChangeValidate = false,
	onBlurValidate = false,
	register = {},
	registerFunc,
	onBlur,
	onFocus,
	errors,
	isValid,
	triggerValidation,
	helper,
	hasAutoGrow,
	rows = 4,
	hideLabel = false,
	width,
	errorTranslations,
}: Props & ClonedProps) => {
	// Turn HTML attributes into validation messages
	const requiredRegister = required
		? { required: errorTranslations?.fieldRequired ?? 'This field is required' }
		: null;
	const maxLengthRegister = maxlength
		? {
				maxLength: {
					value: maxlength,
					message: errorTranslations?.cannotBeLongerThan
						? `${errorTranslations?.cannotBeLongerThan} ${maxlength} ${errorTranslations?.characters}`
						: `Cannot be longer than ${maxlength} characters`,
				},
		  }
		: null;
	const minLengthRegister = minlength
		? {
				minLength: {
					value: minlength,
					message: errorTranslations?.mustBeAtLeast
						? `${errorTranslations?.mustBeAtLeast} ${minlength} ${errorTranslations?.charactersLong}`
						: `Must be at least ${minlength} characters long`,
				},
		  }
		: null;
	const patternRegister = pattern
		? {
				pattern: {
					value: pattern,
					message:
						errorTranslations?.correctInformation ??
						"Please make sure you've entered the correct information",
				},
		  }
		: null;

	const propsToRegister = [
		requiredRegister,
		minLengthRegister,
		maxLengthRegister,
		patternRegister,
	];
	// register last so it overwrites others for custom messages
	const registerMerged: RegisterOptions = Object.assign(
		{},
		...propsToRegister,
		register,
	);
	const registeredTextarea = registerFunc(name, registerMerged);

	const handleOnChange = onChangeValidate
		? async (event: ChangeEvent<HTMLTextAreaElement>) => {
				registeredTextarea.onChange(event);
				await triggerValidation(name); // Must be called after react-hook-form's onChange
				onChange?.(event);
		  }
		: (event: ChangeEvent<HTMLTextAreaElement>) => {
				onBlurValidate || registeredTextarea.onChange(event);
				onChange?.(event);
		  };

	const handleOnBlur = onBlurValidate
		? async (event: FocusEvent) => {
				registeredTextarea.onBlur(event);
				await triggerValidation(name); // Must be called after react-hook-form's onBlur
				onBlur?.(event);
		  }
		: (event: FocusEvent) => {
				onChangeValidate || registeredTextarea.onBlur(event);
				onBlur?.(event);
		  };

	return (
		<Styled.TextareaWrap>
			{!hideLabel && (
				<Styled.FormLabel htmlFor={`textarea-${name}`}>
					{label}
				</Styled.FormLabel>
			)}
			{helper && <Styled.Helper>{helper}</Styled.Helper>}
			{hasAutoGrow && (
				<Styled.AutoSizeTextarea
					ref={registeredTextarea.ref}
					id={`textarea-${name}`}
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					onFocus={onFocus}
					disabled={disabled}
					maxLength={maxlength}
					required={required}
					hasError={!isValid}
					hasAutoGrow={hasAutoGrow}
					minRows={rows}
					width={width}
				/>
			)}
			{!hasAutoGrow && (
				<Styled.TextArea
					ref={registeredTextarea.ref}
					id={`textarea-${name}`}
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					onFocus={onFocus}
					disabled={disabled}
					maxLength={maxlength}
					required={required}
					hasError={!isValid}
					minRows={rows}
					width={width}
				/>
			)}
			{errors?.types && (
				<Styled.ErrorList>
					{Object.values(errors.types).map((message) => {
						return <Styled.Error key={`${message}`}>{message}</Styled.Error>;
					})}
				</Styled.ErrorList>
			)}
		</Styled.TextareaWrap>
	);
};

const TextareaWithoutClonedProps = (props: Props) => {
	return <Textarea {...(props as Props & ClonedProps)} />;
};

export default TextareaWithoutClonedProps;
