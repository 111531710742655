export const miscStyles = {
	desktopSidebarWidth: 240,
	toastMaxWidth: 280,
};

export const affColors = { veryDarkBlue: '#061D34' };

export const breakpoints = {
	tablet: 768, // iPad Mini
	desktop: 1152, // Macbook
};

export const zIndexes = {
	negative: {
		xxs: -100,
		xs: -200,
		sm: -300,
		md: -400,
		lg: -500,
		xl: -600,
		xxl: -700,
	},
	xxs: 100,
	xs: 200,
	sm: 300,
	md: 400,
	lg: 500,
	xl: 600,
	xxl: 700,
};
