import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';
import { zIndexes } from '@/src/vars';
const { label } = typography;
const { color, gutter, borderRadius } = constants;

type ThProps = {
	hasTopBorder?: boolean;
	isClickable: boolean;
	sticky?: string;
};

type TdProps = {
	expandableRow?: boolean;
	sticky?: string;
};

export const TableClip = styled.div(() => {
	return css({
		overflow: 'hidden',
	});
});

export const TableWrap = styled.div(() => {
	return css({
		overflowX: 'auto',
	});
});

export const Table = styled.table(() => {
	return css({ width: '100%' });
});

export const Th = styled.th(
	({ hasTopBorder, isClickable, sticky }: ThProps) => {
		return css({
			overflow: 'hidden',
			background: color.offWhite,
			padding: `${gutter * 2}px ${gutter * 3}px `,
			borderBottom: `1px solid ${color.dove}`,
			'&:first-of-type': { borderRadius: `${borderRadius}px 0 0 0` },
			'&:last-of-type': { borderRadius: `0 ${borderRadius}px 0 0` },
			borderTop: hasTopBorder ? `1px solid ${color.dove}` : 'none',
			cursor: isClickable ? 'pointer' : 'auto',
			position: sticky ? 'sticky' : 'relative',
			left: sticky === 'left' ? '0' : 'auto',
			right: sticky === 'right' ? '0' : 'auto',
			zIndex: sticky ? zIndexes.xxs : 'auto',
		});
	},
);

export const ColumnHeader = styled.div(() => {
	return css(
		{
			display: 'grid',
			gridTemplateColumns: 'auto 1fr',
			gap: gutter,
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
		label,
	);
});

export const Header = styled.div(() => {
	return css({ whiteSpace: 'nowrap' });
});

export const SortIndicator = styled.div(() => {
	return css({
		width: 0,
		fontWeight: 'bold',
		fontSize: 9,
		position: 'relative',
		top: -1,
	});
});

export const HoverableTr = styled.tr(() => {
	return css({
		'&:hover > td': {
			background: color.offWhite,
			borderBottom: `1px solid ${color.dove}`,
			boxShadow: `0 -1px 0 0 ${color.dove}`,
		},
	});
});

export const TdClickable = styled.div(() => {
	return css({
		position: 'absolute',
		zIndex: 0,
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
	});
});

export const TdContent = styled.span(() => {
	return css({
		position: 'relative',
		zIndex: 1,
		cursor: 'auto',
	});
});

export const Td = styled.td(({ expandableRow, sticky }: TdProps) => {
	return css({
		padding: `${gutter * 2}px ${gutter * 3}px `,
		borderBottom: `1px solid ${color.dove}`,
		background: color.white,
		cursor: expandableRow ? 'pointer' : 'auto',
		transition: 'all .3s ease',
		position: sticky ? 'sticky' : 'relative',
		left: sticky === 'left' ? '0' : 'auto',
		right: sticky === 'right' ? '0' : 'auto',
		zIndex: sticky ? zIndexes.xxs : 'auto',
	});
});

export const Drawer = styled.div(() => {
	return css({
		padding: `${gutter * 2}px ${gutter * 3}px `,
		borderBottom: `1px solid ${color.dove}`,
		background: color.white,
	});
});

export const Pagination = styled.div(() => {
	return css({
		display: 'grid',
		gridAutoFlow: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		padding: `0 ${gutter * 2}px`,
		userSelect: 'none',
		background: color.white,
	});
});

export const PaginationList = styled.ol(() => {
	return css({
		display: 'grid',
		gridAutoFlow: 'column',
		listStyle: 'none',
	});
});

export const RenderDrawerControl = styled.button(() => {
	return css({
		display: 'block',
		width: '20px',
		height: '20px',
		color: '#2E3238',
		cursor: 'pointer',
		backgroundColor: 'transparent',
		border: '1px solid #8F97A3',
		borderRadius: '50%',
		transition: 'all 120ms linear',
		':hover,:focus': {
			transition: 'all 120ms linear',
			color: '#7e5ffa',
			borderColor: 'currentColor',
		},
	});
});
