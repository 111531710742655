import { IconName } from '@kinsta/icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEventHandler } from 'react';

import * as Styled from '@/src/app-specific-components/Sidebar/SidebarLink/SidebarLink.style';
import { useLogout } from '@/src/hooks';

type Props = {
	href?: string;
	iconType: IconName;
	text: string;
	matchPath?: string;
	onClick?: MouseEventHandler;
	isLogout?: boolean;
};

const SidebarLink = ({
	href,
	iconType,
	text,
	matchPath,
	onClick,
	isLogout,
}: Props) => {
	const router = useRouter();
	const logout = useLogout();

	let isActive = false;
	if ((href === '/' || href === '/admin') && !matchPath) {
		if (router.asPath === '/' || router.asPath === '/admin') {
			isActive = true;
		}
	} else {
		const reg = new RegExp(`^${matchPath || href}`);
		if (router.asPath.match(reg)) {
			isActive = true;
		}
	}

	return (
		<li>
			{onClick || isLogout ? (
				<Styled.Link
					isActive={isActive}
					onClick={(e) => {
						onClick?.(e);
						logout();
					}}
				>
					<Styled.IconWrap isActive={isActive}>
						<Styled.Icon type={iconType} />
					</Styled.IconWrap>

					<span>{text}</span>
				</Styled.Link>
			) : (
				<Link href={href ?? ''} passHref legacyBehavior>
					<Styled.Link isActive={isActive}>
						<Styled.IconWrap isActive={isActive}>
							<Styled.Icon type={iconType} />
						</Styled.IconWrap>

						<span>{text}</span>
					</Styled.Link>
				</Link>
			)}
		</li>
	);
};

export default SidebarLink;
