import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { transparentize } from 'polished';

import { affColors, breakpoints, miscStyles, zIndexes } from '@/src/vars';

const { gutter, color, borderRadius } = constants;

type Props = {
	isExpanded: boolean;
};

export const HamburgerButton = styled.div(() => {
	return css({
		position: 'fixed',
		top: gutter * 2,
		left: 16,
		fontSize: gutter * 2.25,
		cursor: 'pointer',
		padding: '2px 6px',
		zIndex: zIndexes.xxl,
		background: transparentize(0.3, color.dove),
		borderRadius: borderRadius.s,
		backdropFilter: 'blur(2px)',
		[`@media (min-width: ${breakpoints.tablet}px)`]: { display: 'none' },
	});
});

export const Sidebar = styled.nav<Props>(({ isExpanded }) => {
	return css({
		position: 'fixed',
		top: 0,
		left: isExpanded ? 0 : `-${miscStyles.desktopSidebarWidth}px`,
		[`@media (min-width: ${breakpoints.tablet}px)`]: { left: 0 },
		bottom: 0,
		overflowY: 'auto',
		width: miscStyles.desktopSidebarWidth,
		background: affColors.veryDarkBlue,
		paddingTop: gutter * 3,
		paddingLeft: gutter * 2.5,
		paddingRight: gutter * 2.5,
		paddingBottom: 0, // position: fixed; ignores the bottom padding so we use a spacer margin in Styled.Bottom 👇
		display: 'grid',
		gridTemplateRows: 'auto 1fr auto',
		gap: gutter * 3.5,
		transition: 'left .25s ease-out',
		zIndex: zIndexes.xxl,
	});
});

export const SidebarLinks = styled.ul(() => {
	return css({
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		listStyle: 'none',
	});
});

export const Logo = styled.div(() => {
	return css({
		display: 'grid',
		gap: gutter * 1.5,
		gridTemplateColumns: 'auto auto',
		justifyContent: 'flex-start',
		userSelect: 'none',
		paddingLeft: gutter / 2,
		paddingRight: gutter / 2,
	});
});

export const LogoIcon = styled.img(() => {
	return css({
		width: gutter * 2.5,
		height: gutter * 2.5,
		color: color.white,
	});
});

export const LogoText = styled.span(() => {
	return css({
		fontFamily: 'Brandon Text',
		fontSize: 18,
		lineHeight: '1',
		color: color.white,
	});
});

export const Bottom = styled.div(() => {
	return css({
		display: 'grid',
		gridTemplateRows: 'auto auto',
		gap: gutter * 2.5,
		paddingBottom: gutter * 3, // position: fixed; hack from above
		paddingLeft: gutter / 2,
		paddingRight: gutter / 2,
	});
});
