import {
	FieldValues,
	useForm as useHookForm,
	UseFormProps,
} from 'react-hook-form';

const useForm = (overrides: UseFormProps<FieldValues> = {}) => {
	return useHookForm<FieldValues>({
		criteriaMode: 'all',
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		...overrides,
	});
};

export default useForm;
