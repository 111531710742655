import { Language } from '@/src/utilities/graphql-types.gen';

const languageOptions = [
	{
		label: 'English',
		value: Language.ENGLISH,
	},
	{
		label: 'Deutsch',
		value: Language.GERMAN,
	},
	{
		label: 'Français',
		value: Language.FRENCH,
	},
	{
		label: 'Español',
		value: Language.SPANISH,
	},
	{
		label: 'Italiano',
		value: Language.ITALIAN,
	},
	{
		label: 'Nederlands',
		value: Language.DUTCH,
	},
	{
		label: 'Português',
		value: Language.PORTUGUESE,
	},
	{
		label: '日本語',
		value: Language.JAPANESE,
	},
];

export default languageOptions;
