import { Checkbox as StratusCheckbox } from '@kinsta/choice';
import { startCase } from 'lodash';
import { ChangeEvent, ChangeEventHandler, FocusEvent } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

import * as Styled from '@/src/components/Checkbox/Checkbox.style';
import { ClonedProps } from '@/src/components/Form';

type Props = {
	name: string;
	label?: string;
	onChange?: ChangeEventHandler;
	placeholder?: string;
	helper?: string;
	defaultValue?: boolean;
	disabled?: boolean;
	register?: RegisterOptions;
	onChangeValidate?: boolean;
	type?: 'checkbox' | 'switch';
};

const Checkbox = ({
	name,
	label = startCase(name),
	placeholder,
	onChange,
	defaultValue = false,
	disabled,
	onChangeValidate = false,
	register = {},
	registerFunc,
	onBlur,
	onFocus,
	triggerValidation,
	helper,
	type = 'checkbox',
	errors,
	control,
}: Props & ClonedProps) => {
	const registerMerged: RegisterOptions = { ...register };
	const registeredCheckbox = registerFunc(name, registerMerged);

	const handleOnChange = async (
		event: ChangeEvent<HTMLInputElement>,
		_onChange: (...event: any[]) => void,
	) => {
		_onChange(event.target.checked);
		onChangeValidate && (await triggerValidation(name)); // Must be called after react-hook-form's onChange
		onChange?.(event);
	};

	const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
		onBlur?.(event);
	};

	return (
		<div>
			{type === 'checkbox' && (
				<Controller
					control={control}
					name={name}
					defaultValue={defaultValue}
					render={({
						field: { onChange: _onChange, value = defaultValue },
					}) => {
						return (
							<StratusCheckbox
								ref={registeredCheckbox.ref}
								id={`checkbox-${name}`}
								name={name}
								label={label}
								placeholder={placeholder}
								onChange={(e) => handleOnChange(e, _onChange)}
								onBlur={handleOnBlur}
								onFocus={onFocus}
								isDisabled={disabled}
								checked={value}
							/>
						);
					}}
				/>
			)}

			{type === 'switch' && (
				<Controller
					control={control}
					name={name}
					defaultValue={defaultValue}
					render={({
						field: { onChange: _onChange, value = defaultValue },
					}) => {
						return (
							<Styled.StratusSwitch
								ref={registeredCheckbox.ref}
								id={`checkbox-${name}`}
								name={name}
								label={label}
								placeholder={placeholder}
								onChange={(e) => handleOnChange(e, _onChange)}
								onBlur={handleOnBlur}
								onFocus={onFocus}
								isDisabled={disabled}
								checked={value}
							/>
						);
					}}
				/>
			)}

			{helper && <Styled.Helper type={type}>{helper}</Styled.Helper>}

			{errors?.types && (
				<Styled.ErrorList>
					{Object.values(errors.types).map((message) => {
						return <Styled.Error key={`${message}`}>{message}</Styled.Error>;
					})}
				</Styled.ErrorList>
			)}
		</div>
	);
};

const CheckboxWithoutClonedProps = (props: Props) => {
	return <Checkbox {...(props as Props & ClonedProps)} />;
};

export default CheckboxWithoutClonedProps;
