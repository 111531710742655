import { constants } from '@kinsta/base-style';
import { useState } from 'react';
import validator from 'validator';

import { useMakeAffiliateLinkModalQuery } from '@/src/app-specific-components/Modals/MakeAffiliateLinkModal/MakeAffiliateLinkModal.queries.gen';
import * as Styled from '@/src/app-specific-components/Modals/MakeAffiliateLinkModal/MakeAffiliateLinkModal.style';
import { Code, Copy, Form, Input, Modal } from '@/src/components';
import useForm from '@/src/hooks/useForm';

const { gutter } = constants;

type FormValues = {
	kinstaUrl: string;
};

const kinstaDomainRegex = /^https:\/\/kinsta.com\//;
const validation = {
	validate: {
		shouldBeUrl: (v: string) => {
			if (validator.isURL(v)) {
				return true;
			}

			return 'Needs to be a URL';
		},
		shouldBeKinstaUrl: (v: string) => {
			if (v.match(kinstaDomainRegex)) {
				return true;
			}

			return 'Must start with https://kinsta.com/';
		},
	},
};

const MakeAffiliateLinkModal = () => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [generatedUrl, setGeneratedUrl] = useState<string | null>(null);
	const form = useForm();
	const { data, loading } = useMakeAffiliateLinkModalQuery();

	if (loading) {
		return null;
	}

	if (data?.user?.__typename !== 'Affiliate') {
		return null;
	}

	const handleSubmit = async (formData: Partial<FormValues>) => {
		if (data?.user?.__typename !== 'Affiliate') {
			return null;
		}
		const appendedURL = new URL(formData.kinstaUrl ?? '');
		if (appendedURL) {
			appendedURL.searchParams.append('kaid', data.user.kaid);
		}
		!generatedUrl && appendedURL.href
			? setGeneratedUrl(appendedURL.href)
			: setGeneratedUrl(null);
	};

	const handleCancel = () => {
		form.reset();
		setGeneratedUrl(null);
		setIsExpanded(false);
	};

	const errorTranslations = {
		fieldRequired: data.user.translation.settings.errors.fieldRequired,
		cannotBeLongerThan:
			data.user.translation.settings.errors.cannotBeLongerThan,
		characters: data.user.translation.settings.errors.characters,
		mustBeAtLeast: data.user.translation.settings.errors.mustBeAtLeast,
		charactersLong: data.user.translation.settings.errors.charactersLong,
		correctInformation:
			data.user.translation.settings.errors.correctInformation,
		errorLoadingCategoryList:
			data.user.translation.settings.errors.errorLoadingCategoryList,
		errorLoadingCountryList:
			data.user.translation.settings.errors.errorLoadingCountryList,
	};

	return (
		<Styled.MakeAffiliateLinkModal>
			<Styled.MakeAffiliateLinkButton
				onClick={() => {
					return setIsExpanded(true);
				}}
			>
				{data.user.translation.menu.makeLink}
			</Styled.MakeAffiliateLinkButton>

			<Modal
				visible={isExpanded}
				title={data.user.translation.createAffiliateLink.modalTitle}
				okText={
					generatedUrl
						? data.user.translation.createAffiliateLink.back
						: data.user.translation.createAffiliateLink.createLink
				}
				onOk={form.handleSubmit(handleSubmit)}
				width={480}
				onCancel={handleCancel}
				cancelText={
					generatedUrl
						? data.user.translation.createAffiliateLink.close
						: data.user.translation.createAffiliateLink.cancel
				}
			>
				<Styled.Content>
					{!generatedUrl && (
						<Form form={form} onSubmit={handleSubmit}>
							<p style={{ marginBottom: gutter * 2 }}>
								{data.user.translation.createAffiliateLink.modalDescription}
							</p>
							<Input
								name='kinstaUrl'
								label={
									data.user.translation.createAffiliateLink.modalUrlPlaceholder
								}
								register={validation}
								errorTranslations={errorTranslations}
								required
							/>
						</Form>
					)}

					{generatedUrl && (
						<Styled.GeneratedLinks>
							{data.user.translation.createAffiliateLink.modalGeneratedLink}
							<div>
								<strong>
									{data.user.translation.createAffiliateLink.affiliateUrl}
								</strong>
								<Styled.CodeWrap>
									<Code>{generatedUrl}</Code>
									<Copy text={generatedUrl} />
								</Styled.CodeWrap>
							</div>

							<div>
								<strong>
									{data.user.translation.createAffiliateLink.affiliateLink}
								</strong>
								<Styled.CodeWrap>
									<Code>
										&lt;a rel=&quot;sponsored&quot; href=&quot;{generatedUrl}
										&quot;&gt;Kinsta&lt;/a&gt;
									</Code>
									<Copy
										text={`&lt;a rel=&quot;sponsored&quot; href=&quot;${generatedUrl}&quot;&gt;Kinsta&lt;/a&gt;`}
									/>
								</Styled.CodeWrap>
							</div>
						</Styled.GeneratedLinks>
					)}
				</Styled.Content>
			</Modal>
		</Styled.MakeAffiliateLinkModal>
	);
};

export default MakeAffiliateLinkModal;
