import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { Icon as KIcon } from '@kinsta/icons';

const { color } = constants;

type ArrowProps = {
	direction: 'left' | 'right' | 'up' | 'down';
	size?: number;
	animate: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
};

const getRotate = (direction: ArrowProps['direction']) => {
	if (direction === 'left') {
		return 'rotate(90deg)';
	}
	if (direction === 'right') {
		return 'rotate(-90deg)';
	}
	if (direction === 'up') {
		return 'rotate(180deg)';
	}
	if (direction === 'down') {
		return 'rotate(0deg)';
	}
};

const getHover = (direction: ArrowProps['direction'], animate: boolean) => {
	if (!animate) {
		return '';
	}
	if (direction === 'up') {
		return 'translateY(-1px)';
	}
	if (direction === 'down') {
		return 'translateY(1px)';
	}
	if (direction === 'left') {
		return 'translateX(-1px)';
	}
	if (direction === 'right') {
		return 'translateX(1px)';
	}
};

export const Arrow = styled.button(
	({ direction, size, animate, onClick, disabled }: ArrowProps) => {
		return css({
			background: 'none',
			border: 0,
			width: size,
			height: size,
			display: 'grid',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: disabled ? 'default' : 'pointer',
			transition: 'all .3s ease',
			color: color.lightGrey,
			'&:hover, &:active': onClick && {
				transform: getHover(direction, animate),
				color: color.anthracite,
			},
		});
	},
);

export const Icon = styled(KIcon)(
	({ direction }: Pick<ArrowProps, 'direction'>) => {
		return css({ transform: getRotate(direction) });
	},
);
