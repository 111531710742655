import 'react-toastify/dist/ReactToastify.css';

import { constants } from '@kinsta/base-style';

import { breakpoints, zIndexes } from '@/src/vars';

const { color, gutter, borderRadius } = constants;

export default `
.Toastify__toast-container {
	position: fixed;
	left: ${gutter}px;
	right: ${gutter}px;
	bottom: 0;
	overflow-y: auto;
	max-height: 100vh;
	z-index: ${zIndexes.xxl + 1};
	padding: 0;
	padding-right: 16px;
	box-sizing: border-box;
	width: 100%;
	top: auto;
	padding-top: ${gutter}px;
}

@media (min-width: ${breakpoints.tablet}px) {
	.Toastify__toast-container {
		padding-top: 104px;
		right: 0;
		left: auto;
		width: 296px;
		bottom: auto;
	}
}

.Toastify__toast {
	position: relative;
	margin-bottom: ${gutter}px;
	box-shadow: none;
	justify-content: flex-start;
	max-height: initial;
	font-family: Brandon, Arial, -apple-system, BlinkMacSystemFont, sans-serif;
	cursor: default;
	background: ${color.anthracite};
	padding: ${gutter * 2}px;
	gap: ${gutter * 2}px;
	border-radius: ${borderRadius}px;
	display: grid;
	grid-template-columns: 1fr 24px;
	align-items: flex-start;
	min-height: 0;
}

@media (min-width: ${breakpoints.tablet}px) {
	.Toastify__toast {
		margin-bottom: ${gutter * 2}px;
	}
}

.Toastify__toast-body {
	display: grid;
	grid-template-columns: 24px 1fr;
	gap: ${gutter * 2}px;
	padding: 0;
	margin: 0;
}

.Toastify__toast-body > svg {
	position: relative;
	width: 24px;
	height: 24px;
	border: 2px solid ${color.green};
	border-radius: 999px;
}

.Toastify__toast--error > div > svg {
	border: 0;
	color: ${color.red};
}

.Toastify__close-button {
	background: none;
	cursor: pointer;
	opacity: 1;
	color: ${color.mediumGrey};
	border: 2px solid transparent;
	width: 24px;
	height: 24px;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: color .2s ease;
}

.Toastify__close-button:hover {
	color: ${color.white};
}

.Toastify__close-button > svg {
	width: 13px;
	height: 13px;
	position: relative;
	top: -.5px;
	left: .5px;
}

.Toastify__close-button:focus,
.Toastify__close-button:active {
	color: ${color.white};
	background: #42464D;
	border-color: #3F3185;
	border-color: white;
}
`;
