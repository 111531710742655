import { CSSProperties, ReactNode } from 'react';

import * as Styled from '@/src/components/MetadataList/MetadataList.style';

type Metadata = {
	term: ReactNode;
	detail: ReactNode;
};

type Props = {
	data: Metadata[];
	style?: CSSProperties;
};

const MetadataList = ({ data, style }: Props) => {
	return (
		<dl style={style}>
			{data.map(({ term, detail }) => {
				return (
					<div key={JSON.stringify(term)}>
						<Styled.DescriptionTerm>{term}</Styled.DescriptionTerm>
						<Styled.DescriptionDetail>{detail}</Styled.DescriptionDetail>
					</div>
				);
			})}
		</dl>
	);
};

export default MetadataList;
