import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter, color } = constants;

export const SidebarUser = styled.div(() => {
	return css({
		display: 'grid',
		gridTemplateColumns: '32px auto',
		gap: gutter * 2,
		alignItems: 'center',
	});
});

export const UserAvatar = styled.img(() => {
	return css({
		width: 32,
		height: 32,
		borderRadius: '50%',
	});
});

export const UserDetails = styled.div(() => {
	return css({
		display: 'grid',
		gridTemplateRows: 'auto auto',
		gap: gutter / 2,
		color: color.white,
		lineHeight: 1,
	});
});

export const UserId = styled.span(() => {
	return css({
		lineHeight: 1,
		whiteSpace: 'pre',
	});
});
