import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { typography } from '@kinsta/base-style';
import { Switch } from '@kinsta/choice';

type HelperProps = {
	type?: 'checkbox' | 'switch';
};

export const ErrorList = styled.ul(() => {
	return css({ listStyle: 'none' });
});

export const Error = styled.li(() => {
	return css({ ...typography.error });
});

export const FormLabel = styled.label(() => {
	return css({ ...typography.formLabel });
});

export const Helper = styled.span(({ type }: HelperProps) => {
	return css(typography.description, {
		paddingLeft: type === 'checkbox' ? 24 : 48,
	});
});

// TODO: Eliminate when fixed in Stratus
export const StratusSwitch = styled(Switch)(() => {
	return css({
		'[role="checkbox"][for^="checkbox"]::before': { minWidth: 40 },
	});
});
