import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

import { breakpoints } from '@/src/vars';

const { gutter } = constants;

export const PageContent = styled.div(() => {
	return css({
		padding: gutter * 3,
		paddingTop: gutter,
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			padding: gutter * 4,
			paddingTop: gutter,
		},
	});
});
