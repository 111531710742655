import { AnimatePresence, motion } from 'framer-motion';
import { Router } from 'next/router';
import { ReactNode } from 'react';

import { Sidebar } from '@/src/app-specific-components';
import * as Styled from '@/src/app-specific-components/Layout/Layout.style';
import { LanguageSwitcher } from '@/src/components';
import { Language } from '@/src/utilities/graphql-types.gen';
import languageToLocale from '@/src/utilities/languageToLocale';

type Props = {
	children: ReactNode;
	router: Router;
};

const baseOutsideAppRoutes = [
	'/login',
	'/register',
	'/forgot-password',
	'/new-password',
	'/new-password/[jwt]',
	'/404',
];
const languageArray = Object.values(Language);
const localeArray = languageArray.map((language) => {
	return languageToLocale[language];
});
let outsideAppRoutes: string[] = [];
localeArray.forEach((locale) => {
	return baseOutsideAppRoutes.forEach((route) => {
		if (locale !== languageToLocale[Language.ENGLISH]) {
			outsideAppRoutes.push(`/${locale + route}`);
		} else {
			outsideAppRoutes.push(route);
		}
	});
});

const isFullPage = (router: Router) => {
	if (outsideAppRoutes.includes(router.route)) {
		return true;
	}
};

const Layout = ({ children, router }: Props) => {
	return (
		<Styled.Layout isFullpage={isFullPage(router)}>
			{outsideAppRoutes.includes(router.route) && <LanguageSwitcher />}
			{!outsideAppRoutes.includes(router.route) && <Sidebar />}

			{/* Currently needed to prevent queries refetching on logout */}
			{router.route === '/login' ? (
				<AnimatePresence exitBeforeEnter>
					{/* This is a dummy exit wrapper because each page MUST have an exit animation */}
					<motion.div key={router.route} exit={{}} transition={{ duration: 0 }}>
						<Styled.Main>{children}</Styled.Main>
					</motion.div>
				</AnimatePresence>
			) : (
				<Styled.Main>{children}</Styled.Main>
			)}
		</Styled.Layout>
	);
};

export default Layout;
