import { CSSObject } from '@emotion/react';
import { constants } from '@kinsta/base-style';
import { darken } from 'polished';
import { ContainerState } from 'react-select/src/components/containers';

import { breakpoints, zIndexes } from '@/src/vars';

const { color, borderRadius, shadow, gutter } = constants;

type State = {
	isDisabled: boolean;
	isFocused: boolean;
	menuIsOpen?: boolean;
};

const getOptionBg = (state: any, active?: boolean) => {
	const { isSelected, isFocused } = state;

	if (isSelected && isFocused) {
		if (active) {
			return darken(0.05, color.purple);
		}

		return darken(0.03, color.purple);
	}
	if (isSelected) {
		if (active) {
			return darken(0.02, color.purple);
		}

		return color.purple;
	}
	if (!isSelected && isFocused) {
		if (active) {
			return darken(0.01, color.dove);
		}

		return color.dove;
	}

	if (active) {
		return darken(0.01, color.white);
	}

	return color.white;
};

const getControlBorderColor = (isDisabled: boolean, isValid: boolean) => {
	if (isDisabled) {
		return color.mediumGrey;
	}
	if (!isValid) {
		return color.red;
	}

	return color.lightGrey;
};

const reactSelectStyleOverrides = (isValid: boolean) => {
	return {
		container: (provided: CSSObject, { isDisabled }: ContainerState) => {
			return <CSSObject & { pointerEvents: string }>{
				...provided,
				color: color.anthracite,
				pointerEvents: 'auto',
				cursor: isDisabled ? 'not-allowed' : 'default',
			};
		},
		control: (provided: CSSObject, { isDisabled }: State) => {
			return <CSSObject & { pointerEvents: string }>{
				...provided,
				background: isDisabled ? color.dove : color.white,
				borderColor: getControlBorderColor(isDisabled, isValid),
				boxShadow: 'none',
				transition: 'border, border-color .3s ease',
				pointerEvents: isDisabled ? 'none' : 'auto',
				height: 36,
				'&:hover, &:focus-within': {
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: !isValid ? color.red : color.deepPurple,
				},
				[`@media (min-width: ${breakpoints.tablet}px)`]: { height: 40 },
				borderRadius: '14px',
			};
		},
		dropdownIndicator: (provided: CSSObject) => {
			return {
				...provided,
				color: color.mediumGrey,
				transition: 'color .3s ease',
				'&:hover, &:focus': { color: color.anthracite },
			};
		},
		indicatorsContainer: (provided: CSSObject) => {
			return {
				...provided,
				width: 32,
				cursor: 'pointer',
			};
		},
		indicatorSeparator: () => {
			return { display: 'none' };
		},
		input: (provided: CSSObject) => {
			return {
				...provided,
				color: color.anthracite,
				padding: 0,
				margin: 0,
			};
		},
		menu: (provided: CSSObject) => {
			return {
				...provided,
				boxShadow: shadow.large,
				zIndex: zIndexes.lg,
			};
		},
		menuList: (provided: CSSObject) => {
			return {
				...provided,
				padding: 0,
			};
		},
		noOptionsMessage: () => {
			// This is set in Styled.NoResults so we are blowing away the provided styles here.
			return {};
		},
		option: (provided: CSSObject, state: State) => {
			return {
				...provided,
				cursor: 'pointer',
				background: getOptionBg(state),
				transition: 'background .2s ease',
				'&:active': { background: getOptionBg(state, true) },
				'&:first-of-type': {
					borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
				},
				'&:last-of-type': {
					borderRadius: `0 0 ${borderRadius}px ${borderRadius}px`,
				},
				'&:only-of-type': borderRadius.s,
				height: 36,
				display: 'grid',
				alignItems: 'center',
				[`@media (min-width: ${breakpoints.tablet}px)`]: { height: 40 },
			};
		},
		placeholder: (provided: CSSObject) => {
			return {
				...provided,
				color: color.mediumGrey,
			};
		},
		singleValue: (provided: CSSObject) => {
			return {
				...provided,
				color: color.anthracite,
				marginLeft: 0,
				marginRight: 0,
				padding: 0,
			};
		},
		valueContainer: (
			provided: CSSObject,
			state: { selectProps: { [key: string]: any } },
		) => {
			return {
				...provided,
				cursor: state.selectProps.isSearchable ? 'text' : 'pointer',
				padding: `0 ${gutter * 2}px`,
			};
		},
	};
};

export default reactSelectStyleOverrides;
