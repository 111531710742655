import * as Styled from '@/src/components/Arrow/Arrow.style';

type Props = {
	direction?: 'left' | 'right' | 'down' | 'up';
	onClick?: () => any;
	disabled?: boolean;
	size?: number;
	animate?: boolean;
};

const Arrow = ({
	direction = 'down',
	onClick,
	disabled,
	size = 48,
	animate = false,
}: Props) => {
	return (
		<Styled.Arrow
			aria-disabled={disabled}
			disabled={disabled}
			direction={direction}
			onClick={onClick}
			size={size}
			animate={animate}
		>
			<Styled.Icon type='Chevron' direction={direction} />
		</Styled.Arrow>
	);
};

export default Arrow;
