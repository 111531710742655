import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { color } = constants;

type PaginationButtonProps = {
	selected?: boolean;
};

export const PaginationButton = styled.button(
	({ selected }: PaginationButtonProps) => {
		return css({
			width: 48,
			height: 48,
			cursor: selected ? 'default' : 'pointer',
			background: 'none',
			border: 0,
			borderTop: selected
				? `1px solid ${color.anthracite}`
				: '1px solid transparent',
			position: 'relative',
			top: -1,
			color: selected ? color.anthracite : color.lightGrey,
			transition: 'all .3s ease',
			'&:hover': { color: color.anthracite },
		});
	},
);
