import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import animationVariants from '@/src/animations';
import * as Styled from '@/src/app-specific-components/TwoColumnLayout/TwoColumnLayout.style';
import { Card } from '@/src/components';

type Props = {
	children: ReactNode;
};

const TwoColumnLayout = ({ children }: Props) => {
	const router = useRouter();

	const { kaid } = router.query;
	const { pathname } = router;

	return (
		<>
			<Styled.TwoColumnLayout>
				<nav>
					<Styled.NavLinks>
						<Styled.Li>
							<Link
								href='/admin/affiliates/[kaid]/profile'
								as={`/admin/affiliates/${kaid}/profile`}
								passHref
								legacyBehavior
							>
								<Styled.NavLink
									active={pathname === '/admin/affiliates/[kaid]/profile'}
								>
									Profile
								</Styled.NavLink>
							</Link>
						</Styled.Li>
						<Styled.Li>
							<Link
								href='/admin/affiliates/[kaid]/referrals'
								as={`/admin/affiliates/${kaid}/referrals`}
								passHref
								legacyBehavior
							>
								<Styled.NavLink
									active={pathname === '/admin/affiliates/[kaid]/referrals'}
								>
									Referrals
								</Styled.NavLink>
							</Link>
						</Styled.Li>
						<Styled.Li>
							<Link
								href='/admin/affiliates/[kaid]/upcoming-payment'
								as={`/admin/affiliates/${kaid}/upcoming-payment`}
								passHref
								legacyBehavior
							>
								<Styled.NavLink
									active={
										pathname === '/admin/affiliates/[kaid]/upcoming-payment'
									}
								>
									Upcoming Payment
								</Styled.NavLink>
							</Link>
						</Styled.Li>
						<Styled.Li>
							<Link
								href='/admin/affiliates/[kaid]/payments'
								as={`/admin/affiliates/${kaid}/payments`}
								passHref
								legacyBehavior
							>
								<Styled.NavLink
									active={pathname === '/admin/affiliates/[kaid]/payments'}
								>
									Payments
								</Styled.NavLink>
							</Link>
						</Styled.Li>
						<Styled.Li>
							<Link
								href='/admin/affiliates/[kaid]/actions'
								as={`/admin/affiliates/${kaid}/actions`}
								passHref
								legacyBehavior
							>
								<Styled.NavLink
									active={pathname === '/admin/affiliates/[kaid]/actions'}
								>
									Actions
								</Styled.NavLink>
							</Link>
						</Styled.Li>
					</Styled.NavLinks>
				</nav>

				<Card>
					<motion.div
						style={{ display: 'grid' }}
						initial='initial'
						animate='default'
						exit='exit'
						variants={animationVariants.slideOutRightSlideInLeft}
					>
						{children}
					</motion.div>
				</Card>
			</Styled.TwoColumnLayout>
		</>
	);
};

export default TwoColumnLayout;
