import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { typography } from '@kinsta/base-style';

export const ErrorList = styled.ul(() => {
	return css({ listStyle: 'none' });
});

export const Error = styled.li(() => {
	return css({ ...typography.error });
});

export const FormLabel = styled.label(() => {
	return css({ display: 'block' }, typography.formLabel);
});

export const Helper = styled.label(() => {
	return css({ display: 'block' }, typography.description);
});
