import { ReactNode } from 'react';

import * as Styled from '@/src/components/Timeline/Timeline.style';
import TimelineItem from '@/src/components/Timeline/TimelineItem';
const { StyledUL } = Styled;

type Props = {
	data: {
		date: ReactNode;
		title: string;
		message: ReactNode;
	}[];
	color?: string;
};

const Timeline = ({ data, color }: Props) => {
	return (
		<StyledUL>
			{data.map((item, index) => {
				return (
					<TimelineItem
						key={index}
						title={item.title}
						message={item.message}
						date={item.date}
						endNode={data.length === index + 1}
						color={color}
					/>
				);
			})}
		</StyledUL>
	);
};

export default Timeline;
