import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';

import { Variant } from '@/src/components/ErrorBoundary';
import { breakpoints } from '@/src/vars';

const { gutter, borderRadius, color } = constants;

type Props = {
	variant: Variant;
};

export const DisplayError = styled.div<Props>(({ variant }) => {
	return css(
		{ background: color.white },
		variant === 'page' && {
			display: 'grid',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			padding: gutter * 4,
		},
	);
});

export const Layout = styled.div<Props>(({ variant }) => {
	return css(
		{
			display: 'grid',
			alignItems: 'center',
			maxWidth: 768,
		},
		variant === 'page'
			? {
					gridTemplateRows: 'auto 1fr',
					alignItems: 'flex-start',
					[`@media (min-width: ${breakpoints.tablet}px)`]: {
						gridTemplateRows: 'auto',
						gridTemplateColumns: '1fr auto',
						alignItems: 'center',
						gap: `${gutter * 4}px`,
					},
			  }
			: {
					gridTemplateRows: 'auto auto',
					gap: `${gutter * 3}px`,
			  },
	);
});

export const Heading = styled.h2<Props>(({ variant }) => {
	return css(
		{ marginBottom: `${gutter}px` },
		variant === 'page' ? typography.headline : typography.cardTitle,
	);
});

export const Note = styled.p(() => {
	return css({ marginBottom: `${gutter * 2}px` });
});

export const Message = styled.div(() => {
	return css({
		background: color.dove,
		borderRadius: borderRadius.s,
		fontFamily: 'Courier, monospace',
		padding: '4px 8px',
		marginBottom: `${gutter * 4}px`,
		whiteSpace: 'pre-line',
	});
});

export const Image = styled.img<Props>(({ variant }) => {
	return css(
		variant === 'page'
			? {
					order: -1,
					maxWidth: 170,
					marginBottom: gutter * 2,
					[`@media (min-width: ${breakpoints.tablet}px)`]: {
						order: 2,
						maxWidth: 240,
						marginBottom: 0,
					},
			  }
			: { width: '170px' },
	);
});
