import { useRouter } from 'next/router';

import { useLogoutMutation } from '@/src/hooks/useLogout/useLogout.queries.gen';
import { useApollo } from '@/src/utilities/apollo';

const useLogout = () => {
	const [logoutMutation] = useLogoutMutation();
	const client = useApollo();
	const router = useRouter();

	const logout = async (allSessions = false) => {
		logoutMutation({ variables: { allSessions } }).then(() => {
			client.clearStore();
			router.push('/login');
		});
	};

	return logout;
};

export default useLogout;
