import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter } = constants;

export const DescriptionTerm = styled.dt(() => {
	return css({
		display: 'inline',
		fontWeight: 'bold',
		marginRight: gutter,
		'&::after': { content: '":"' },
	});
});

export const DescriptionDetail = styled.dd(() => {
	return css({ display: 'inline' });
});

export const MetadataWrap = styled.div(() => {
	return css({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		gap: '1rem',
	});
});
