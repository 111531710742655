import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

import { Button } from '@/src/components';

const { gutter, color } = constants;

export const MakeAffiliateLinkModal = styled.div(() => {
	return css({ position: 'relative' });
});

export const Content = styled.div(() => {
	return css({
		display: 'grid',
		gridAutoRows: 'auto',
		gap: gutter * 2,
	});
});

export const MakeAffiliateLinkButton = styled(Button)(() => {
	return css({ '> span': { width: '100%' } });
});

export const GeneratedLinks = styled.div(() => {
	return css({
		display: 'grid',
		gridAutoRows: 'auto',
		gap: gutter * 2,
		'> div': {
			display: 'grid',
			gridAutoRows: 'auto',
			gap: gutter / 2,
		},
	});
});

export const ErrorNote = styled.p(() => {
	return css({ color: color.red });
});

export const CodeWrap = styled.div(() => {
	return css({
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		alignItems: 'center',
		gap: gutter * 2,
		'> code': { overflow: 'scroll' },
	});
});
