import SidebarLink from '@/src/app-specific-components/Sidebar/SidebarLink';

const SidebarSellerAdminNav = () => {
	return (
		<>
			<SidebarLink href='/sales/deals' iconType='MenuCompany' text='Deals' />
			<SidebarLink href='/sales/sellers' iconType='MenuUsers' text='Sellers' />
			<SidebarLink iconType='MenuLogout' text='Log out' isLogout />
		</>
	);
};

export default SidebarSellerAdminNav;
