import {
	DocumentNode,
	LazyQueryHookOptions,
	MutationHookOptions,
	QueryHookOptions,
	TypedDocumentNode,
	useLazyQuery,
	useMutation as useApolloMutation,
	useQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';

import { Toast } from '@/src/components';

// Override Apollo's useMutation so we can pop a toast by default for every error.
// This way we don't have to explicitly catch/handle every error on the frontend.
function useMutation<TData, TVariables>(
	mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: MutationHookOptions<TData, TVariables>,
) {
	return useApolloMutation(mutation, {
		onError: (err) => {
			toast.error(<Toast title='Error' message={err.message} />);
		},
		...options,
	});
}

export type { QueryHookOptions, LazyQueryHookOptions, MutationHookOptions };

export { useMutation, useQuery, useLazyQuery };
