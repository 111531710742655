import { CSSProperties, ReactNode } from 'react';

import * as Styled from '@/src/components/TextContent/TextContent.style';

type Props = {
	children: ReactNode;
	style?: CSSProperties;
};

const TextContent = ({ children, style }: Props) => {
	return <Styled.TextContent style={style}>{children}</Styled.TextContent>;
};

export default TextContent;
