const animationVariants = {
	headline: {
		initial: {
			x: -50,
			opacity: 0,
		},
		default: {
			x: 0,
			opacity: 1,
			transition: { ease: 'easeOut' },
		},
		exit: {
			x: 50,
			opacity: 0,
			transition: { ease: 'easeOut' },
		},
	},
	content: {
		initial: {
			y: 50,
			opacity: 0,
		},
		default: {
			y: 0,
			opacity: 1,
			transition: {
				ease: 'easeOut',
				delay: 0.2,
			},
		},
		exit: {
			y: -25,
			opacity: 0,
			transition: { ease: 'easeIn' },
		},
	},
	slideOutRightSlideInLeft: {
		initial: {
			x: 25,
			opacity: 0,
		},
		default: {
			x: 0,
			opacity: 1,
			transition: {
				ease: 'easeOut',
				delay: 0.1,
			},
		},
		exit: {
			x: 50,
			opacity: 0,
			transition: { ease: 'easeIn' },
		},
	},
	slideOutUpSlideInDown: {
		initial: { height: 0 },
		default: {
			height: 'auto',
			transition: {
				ease: 'easeOut',
				delay: 0.1,
			},
		},
		exit: {
			height: 0,
			transition: { ease: 'easeIn' },
		},
	},
};

export default animationVariants;
