import { format as formatFn, isValid, Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { CSSProperties } from 'react';

import { useDateTimeQuery } from './DateTime.queries.gen';

type Props = {
	timestamp: number | string | null | undefined;
	style?: CSSProperties;
	locale?: Locale;
	format?: string;
};

const DateTime = ({
	timestamp,
	style,
	locale = enUS,
	format = 'yyyy MMM dd',
}: Props) => {
	const { data: translationData } = useDateTimeQuery();

	if (timestamp === null || timestamp === undefined) {
		return null;
	}

	if (!isValid(new Date(Number(timestamp)))) {
		// TODO: send error to Sentry

		if (translationData?.user?.__typename !== 'Affiliate') {
			return null;
		}

		return <span style={style}>{translationData.user.translation.noData}</span>;
	}

	const time = Number(timestamp);

	return (
		<span
			title={formatFn(time, 'yyyy-MM-dd HH:mm:ss', { locale })}
			style={style}
		>
			{formatFn(time, format, { locale })}
		</span>
	);
};

export default DateTime;
