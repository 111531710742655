import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter, borderRadius, color } = constants;

type Props = {
	isCenteredContent?: boolean;
	isPadded?: boolean;
};

export const Card = styled.div(({ isCenteredContent, isPadded }: Props) => {
	return css({
		display: 'grid',
		background: color.white,
		borderRadius: borderRadius.s,
		boxShadow: `
			0px 2px 4px rgba(83, 51, 237, 0.08),
			0px 8px 24px rgba(11, 14, 102, 0.08)
		`,
		alignItems: isCenteredContent ? 'center' : 'stretch',
		justifyContent: isCenteredContent ? 'center' : 'stretch',
		textAlign: isCenteredContent ? 'center' : 'left',
		padding: isPadded ? gutter * 3 : 0,
		alignSelf: 'stretch',
	});
});
