import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import animationVariants from '@/src/animations';
import * as Styled from '@/src/components/PageContent/PageContent.style';

type Props = {
	children: ReactNode;
	animate?: boolean;
};

const PageContent = ({ children, animate = true }: Props) => {
	return (
		<>
			{animate ? (
				<motion.div
					initial='initial'
					animate='default'
					exit='exit'
					variants={animationVariants.content}
				>
					<Styled.PageContent>{children}</Styled.PageContent>
				</motion.div>
			) : (
				<Styled.PageContent>{children}</Styled.PageContent>
			)}
		</>
	);
};

export default PageContent;
