import { IconName } from '@kinsta/icons';
import { ReactElement } from 'react';
import { ToastOptions } from 'react-toastify';

import * as Styled from '@/src/components/Toast/Toast.style';

type Props = {
	title?: string;
	message?: ReactElement | string;
	icon?: IconName;
	toastProps?: ToastOptions;
};

const getIcon = (variant: ToastOptions['type']): IconName => {
	if (variant === 'success') {
		return 'Check';
	}
	if (variant === 'error') {
		return 'ErrorStroke';
	}

	return 'InfoCircle';
};

const Toast = ({ title = 'Done', message, icon, toastProps }: Props) => {
	const variant = toastProps?.type;

	return (
		<>
			<Styled.Icon variant={variant} type={icon || getIcon(variant)} />
			<Styled.Content>
				<h4>{title}</h4>
				{message && <div>{message}</div>}
			</Styled.Content>
		</>
	);
};

export default Toast;
