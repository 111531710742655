import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { breakpoints, miscStyles } from '@/src/vars';

type LayoutProps = {
	isFullpage?: boolean;
};

export const Layout = styled.div(({ isFullpage }: LayoutProps) => {
	return css({
		display: 'grid',
		minHeight: '100vh',
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			paddingLeft: isFullpage ? 0 : `${miscStyles.desktopSidebarWidth}px`,
		},
	});
});

export const Main = styled.main(() => {
	return css({ minHeight: '100vh' });
});
