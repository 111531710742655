import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { darken } from 'polished';

import { breakpoints } from '@/src/vars';

const { gutter, color, borderRadius } = constants;

export const PreWrap = styled.div(() => {
	return css({
		maxWidth: 'calc(100vw - 72px)',
		overflowX: 'auto',
		padding: `${gutter}px ${gutter * 2}px`,
		background: darken(0.025, color.dove),
		borderRadius: borderRadius.s,
		border: `1px solid ${darken(0.075, color.dove)}`,
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			maxWidth: 'calc(100vw - 328px)',
		},
	});
});

export const Pre = styled.pre(() => {
	return css({ fontFamily: 'monospace' });
});
