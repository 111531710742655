import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants, typography } from '@kinsta/base-style';

import { zIndexes } from '@/src/vars';

const { gutter, color } = constants;
const { Headline } = typography;

export const Chart = styled.div(() => {
	return css({
		overflowX: 'hidden',
		position: 'relative',
	});
});

export const Total = styled(Headline)(() => {
	return css({
		color: color.purple,
		marginLeft: gutter * 3,
	});
});

export const Wrap = styled.div(() => {
	return css({
		paddingBottom: gutter * 1.5,
		zIndex: zIndexes.xxs,
		position: 'relative',
	});
});

export const ScrollWrapper = styled.div(() => {
	return css({
		overflowX: 'auto',
		margin: `0 -${gutter * 2}px`,
	});
});
