import { Language } from '@/src/utilities/graphql-types.gen';

const languageToLocale = {
	[Language.ENGLISH]: 'en',
	[Language.FRENCH]: 'fr',
	[Language.ITALIAN]: 'it',
	[Language.SPANISH]: 'es',
	[Language.GERMAN]: 'de',
	[Language.DUTCH]: 'nl',
	[Language.PORTUGUESE]: 'pt',
	[Language.JAPANESE]: 'ja',
};

export default languageToLocale;
