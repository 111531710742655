import { Link } from '@kinsta/link';

import { Button } from '@/src/components';
import * as Styled from '@/src/components/DisplayError/DisplayError.style';
import { Variant } from '@/src/components/ErrorBoundary';

type Props = {
	message: string;
	resetFunction?: () => void;
	refreshPage?: boolean;
	variant?: Variant;
};

const DisplayError = ({
	message,
	resetFunction,
	refreshPage,
	variant = 'component',
}: Props) => {
	return (
		<Styled.DisplayError variant={variant}>
			<Styled.Layout variant={variant}>
				<div>
					<Styled.Heading variant={variant}>
						Something went wrong
					</Styled.Heading>

					<Styled.Note>
						Try {variant === 'page' ? 'refreshing' : 'again'}. If you still see
						this message KAffiliates might be down.
						<br />
						<Link
							href='https://status.kinsta.com/'
							icon='ExternalUrl'
							target='_blank'
							rel='noopener noreferrer'
						>
							Check our Status page for updates
						</Link>
					</Styled.Note>

					{message && <Styled.Message>{message}</Styled.Message>}

					{resetFunction && <Button onClick={resetFunction}>Try again</Button>}

					{refreshPage && (
						<Button
							onClick={() => {
								return window.location.reload();
							}}
						>
							Refresh page
						</Button>
					)}
				</div>

				<Styled.Image variant={variant} src='/img/error-island.svg' />
			</Styled.Layout>
		</Styled.DisplayError>
	);
};

export default DisplayError;
