import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ACErrorCode } from '@kattribution/utils';
import router from 'next/router';
import { useMemo } from 'react';

import generatedIntrospection from '@/src/utilities/graphql-types.gen';

let apolloClient: ApolloClient<any>;

const isServer = typeof window === 'undefined';

const getLocalURI = () => {
	const currentURL = !isServer && new URL(window.location.href);
	const localAPIuri =
		currentURL &&
		`${currentURL.protocol}//${currentURL.hostname}:${process.env.NEXT_PUBLIC_API_PORT}/graphql`;

	return localAPIuri || '';
};

const uri = process.env.NEXT_PUBLIC_API_URI || getLocalURI();

const link = new HttpLink({
	uri,
	credentials: 'include',
});

const createApolloClient = () => {
	const client = new ApolloClient({
		ssrMode: isServer,
		link: from([
			// @ts-ignore: This self-references and is hacky but needed to access 'client'
			onError(({ graphQLErrors }) => {
				if (graphQLErrors) {
					const isInvalidSession = graphQLErrors.some(({ extensions }) => {
						return (
							typeof extensions.code === 'string' &&
							extensions.code in ACErrorCode
						);
					});

					const nonRedirectToPaths = ['/', '/admin'];

					if (!isServer) {
						if (isInvalidSession && router.asPath !== '/login') {
							client.clearStore();
							if (nonRedirectToPaths.includes(router.asPath)) {
								router.push('/login');
							} else {
								router.push(`/login?to=${router.asPath}`);
							}
						}
					}
				}
			}),
			link,
		]),
		cache: new InMemoryCache({
			possibleTypes: generatedIntrospection.possibleTypes,
		}),
	});

	return client;
};

const initializeNewInstanceIfSSR = () => {
	// Important to prevent session leak between visitors!
	if (isServer) {
		apolloClient = createApolloClient();
	}
};

export const initializeApollo = (initialState: any = null) => {
	apolloClient = apolloClient || createApolloClient();

	initializeNewInstanceIfSSR();

	if (initialState) {
		apolloClient.cache.restore(initialState);
	}

	return apolloClient;
};

export const useApollo = (initialState?: any) => {
	const store = useMemo(() => {
		return initializeApollo(initialState);
	}, [initialState]);

	return store;
};
