import SidebarLink from '@/src/app-specific-components/Sidebar/SidebarLink';

const SidebarAffiliateAdminNav = () => {
	return (
		<>
			<SidebarLink href='/admin' iconType='MenuHome' text='Dashboard' />
			<SidebarLink
				href='/admin/affiliates'
				iconType='MenuUser'
				text='Affiliates'
			/>
			<SidebarLink
				href='/admin/referrals'
				iconType='MenuUsers'
				text='Referrals'
			/>
			<SidebarLink
				href='/admin/upcoming-payments'
				iconType='MenuBilling'
				text='Upcoming Payments'
			/>
			<SidebarLink
				href='/admin/payments'
				iconType='MenuCron'
				text='Payment History'
			/>
			<SidebarLink
				href='/admin/email-list'
				iconType='MenuLxdIssues'
				text='Email List'
			/>
			<SidebarLink href='/admin/stats' iconType='MenuAnalytics' text='Stats' />
			<SidebarLink
				href='/admin/settings'
				iconType='MenuSettings'
				text='Settings'
			/>
			<SidebarLink iconType='MenuLogout' text='Log out' isLogout />
		</>
	);
};

export default SidebarAffiliateAdminNav;
