import { constants } from '@kinsta/base-style';

const { gutter, color } = constants;

type Props = {
	x: number;
	y: number;
	index: number;
	hoveredIndex?: number;
	date: string;
};

// Not sure why the x ticks don't center using their x coordinate, but
// this offsets it to center with the colon in the time.
const xOffset = 16;

const ChartXTick = ({ x, y, index, hoveredIndex, date }: Props) => {
	if (index === hoveredIndex) {
		return (
			<text
				x={x - xOffset}
				y={y + gutter}
				style={{
					fontWeight: 'bold',
					fill: color.anthracite,
				}}
			>
				{date}
			</text>
		);
	}

	return (
		<text x={x - xOffset} y={y + gutter}>
			{date}
		</text>
	);
};

export default ChartXTick;
