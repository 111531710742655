import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';
import { Icon as KIcon } from '@kinsta/icons';
import { TypeOptions } from 'react-toastify';

const { color, gutter } = constants;

const getColor = (variant: TypeOptions) => {
	if (variant === 'success') {
		return color.green;
	}
	if (variant === 'error') {
		return color.red;
	}
};

export const Content = styled.div(() => {
	return css({
		display: 'flex',
		flexDirection: 'column',
		gap: gutter,
		color: color.white,
		paddingTop: 2,
	});
});

export const Icon = styled(KIcon)(
	({ variant = 'success' }: { variant?: TypeOptions }) => {
		return css({ color: getColor(variant) });
	},
);
