import { ComponentProps } from 'react';

const CogIcon = (props: ComponentProps<'svg'>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 18 18'
		{...props}
	>
		<path
			fill='currentColor'
			fillRule='evenodd'
			d='M7.529 1.21A.75.75 0 0 1 8.25.666h1.5a.75.75 0 0 1 .721.544l.334 1.17a.5.5 0 0 0 .29.324l.875.363a.5.5 0 0 0 .434-.025l1.064-.59a.75.75 0 0 1 .894.125l1.061 1.06a.75.75 0 0 1 .125.895l-.59 1.063a.5.5 0 0 0-.025.434l.362.876a.5.5 0 0 0 .325.29l1.17.333a.75.75 0 0 1 .543.721v1.5a.75.75 0 0 1-.544.721l-1.17.335a.5.5 0 0 0-.324.29l-.362.875a.5.5 0 0 0 .024.434l.591 1.063a.75.75 0 0 1-.125.895l-1.06 1.06a.75.75 0 0 1-.895.125l-1.064-.59a.5.5 0 0 0-.434-.025l-.875.363a.5.5 0 0 0-.29.324l-.334 1.17a.75.75 0 0 1-.721.544h-1.5a.75.75 0 0 1-.721-.544l-.334-1.17a.5.5 0 0 0-.29-.324l-.875-.363a.5.5 0 0 0-.434.025l-1.064.59a.75.75 0 0 1-.894-.125l-1.06-1.06a.75.75 0 0 1-.126-.895l.59-1.063a.5.5 0 0 0 .025-.434l-.362-.876a.5.5 0 0 0-.325-.29l-1.17-.334a.75.75 0 0 1-.543-.72v-1.5a.75.75 0 0 1 .544-.722l1.17-.334a.5.5 0 0 0 .324-.29l.362-.875a.5.5 0 0 0-.024-.434l-.591-1.063a.75.75 0 0 1 .125-.895l1.06-1.06a.75.75 0 0 1 .895-.126l1.064.59a.5.5 0 0 0 .434.026l.875-.363a.5.5 0 0 0 .29-.324l.334-1.17ZM4.833 9a4.167 4.167 0 1 1 8.334 0 4.167 4.167 0 0 1-8.334 0Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default CogIcon;
