import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon as KIcon } from '@kinsta/icons';

export const CopyIcon = styled(KIcon)(() => {
	return css({
		cursor: 'pointer',
		transition: 'opacity .3s',
		'&:hover, &:focus': { opacity: 0.5 },
		'&:active': { opacity: 0.75 },
	});
});
