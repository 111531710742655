import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

export const Container = styled.th(() => {
	return css({
		display: 'table-cell',
		verticalAlign: 'middle',
		paddingRight: constants.gutter * 3,
		background: constants.color.offWhite,
		width: '52px',
	});
});
