/* eslint-disable */
import * as Types from '../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type AffiliateSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AffiliateSettingsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, settings: { __typename?: 'AffiliateSettings', id: string, language: Types.Language } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const AffiliateSettingsDocument = gql`
    query AffiliateSettings {
  user {
    id
    ... on Affiliate {
      settings {
        id
        language
      }
    }
  }
}
    `;

/**
 * __useAffiliateSettingsQuery__
 *
 * To run a query within a React component, call `useAffiliateSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAffiliateSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>(AffiliateSettingsDocument, options);
      }
export function useAffiliateSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>(AffiliateSettingsDocument, options);
        }
export type AffiliateSettingsQueryHookResult = ReturnType<typeof useAffiliateSettingsQuery>;
export type AffiliateSettingsLazyQueryHookResult = ReturnType<typeof useAffiliateSettingsLazyQuery>;
export type AffiliateSettingsQueryResult = Apollo.QueryResult<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>;