import { typography } from '@kinsta/base-style';
import { Icon } from '@kinsta/icons';

import * as Styled from '@/src/components/Modal/ModalTitle.style';

const { CardTitle } = typography;

type Props = {
	text?: string;
	closeFunction?: () => void;
};

const ModalTitle = ({ text, closeFunction }: Props) => {
	return (
		<Styled.ModalTitle text={text}>
			{text && <CardTitle>{text}</CardTitle>}

			{closeFunction && (
				<Styled.CloseButton onClick={closeFunction}>
					<Icon type='Close' />
				</Styled.CloseButton>
			)}
		</Styled.ModalTitle>
	);
};

export default ModalTitle;
