import { ReactNode } from 'react';

import {
	Button,
	ModalBottom,
	ModalContent,
	ModalTitle,
} from '@/src/components';
import * as Styled from '@/src/components/Modal/Modal.style';

type Props = {
	children: ReactNode;
	visible: boolean;
	title?: string;
	onOk?: (...args: any) => void;
	onCancel?: () => void;
	cancelText?: string;
	okText?: string;
	width?: number;
};

const Modal = ({
	children,
	visible,
	title,
	onOk,
	onCancel,
	cancelText = 'Cancel',
	okText = 'Okay',
	width = 680,
}: Props) => {
	return (
		<Styled.Modal
			isOpen={visible}
			onRequestClose={onCancel}
			shouldCloseOnOverlayClick={true}
			width={width}
		>
			{onCancel && <ModalTitle text={title} closeFunction={onCancel} />}
			{!onCancel && <ModalTitle text={title} />}

			<ModalContent>{children}</ModalContent>

			<ModalBottom>
				{onOk && onCancel && (
					<>
						<Button type='secondary' onClick={onCancel}>
							{cancelText}
						</Button>
						<Button onClick={onOk}>{okText}</Button>
					</>
				)}
				{onOk && !onCancel && <Button onClick={onOk}>{okText}</Button>}
				{!onOk && onCancel && (
					<Button type='secondary' onClick={onCancel}>
						{cancelText}
					</Button>
				)}
			</ModalBottom>
		</Styled.Modal>
	);
};

export default Modal;
