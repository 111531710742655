import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter, color, borderRadius } = constants;

export const ModalBottom = styled.div(() => {
	return css({
		background: color.white,
		borderTop: `1px solid ${color.dove}`,
		padding: `${gutter * 3}px ${gutter * 4}px`,
		borderRadius: `0 0 ${borderRadius}px ${borderRadius}px`,
		display: 'grid',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gridAutoFlow: 'column',
		gap: gutter * 2,
	});
});
