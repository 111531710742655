import Script from 'next/script';

const GTM = () => {
	if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
		return null;
	}

	return (
		<>
			<Script
				src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
			/>
			<Script id='google-analytics'>
				{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){window.dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
			`}
			</Script>
		</>
	);
};

export default GTM;
