import { Button as StratusButton } from '@kinsta/button';
import { ButtonInterface } from '@kinsta/button/components/Button';
import { forwardRef } from 'react';

const Button = forwardRef<HTMLButtonElement, ButtonInterface>(
	({ children, ...props }, ref) => {
		return (
			<StratusButton htmlType='submit' ref={ref} {...props}>
				<span>{children}</span>
			</StratusButton>
		);
	},
);

Button.displayName = 'Button';

export default Button;
