import { constants } from '@kinsta/base-style';

const { color } = constants;

type Props = {
	cx: number;
	cy: number;
	active?: boolean;
};

const ChartDot = ({ cx, cy, active }: Props) => {
	if (active) {
		return (
			<circle
				key={`${cx}${cy}`}
				cx={cx}
				cy={cy}
				r='6'
				strokeWidth={3}
				fill={color.purple}
				stroke={color.white}
			/>
		);
	}

	return (
		<circle
			key={`${cx}${cy}`}
			cx={cx}
			cy={cy}
			r='4'
			strokeWidth={2}
			fill={color.purple}
			stroke={color.white}
		/>
	);
};

export default ChartDot;
