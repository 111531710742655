/* eslint-disable */
import * as Types from '../../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type MenuTranslationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MenuTranslationQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', menu: { __typename?: 'MenuTranslation', dashboard: string, referredCustomers: string, referredVisitors: string, payments: string, settings: string, promoMaterial: string, resources: string, logOut: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const MenuTranslationDocument = gql`
    query MenuTranslation {
  user {
    id
    ... on Affiliate {
      translation {
        menu {
          dashboard
          referredCustomers
          referredVisitors
          payments
          settings
          promoMaterial
          resources
          logOut
        }
      }
    }
  }
}
    `;

/**
 * __useMenuTranslationQuery__
 *
 * To run a query within a React component, call `useMenuTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuTranslationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuTranslationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuTranslationQuery, MenuTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MenuTranslationQuery, MenuTranslationQueryVariables>(MenuTranslationDocument, options);
      }
export function useMenuTranslationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuTranslationQuery, MenuTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MenuTranslationQuery, MenuTranslationQueryVariables>(MenuTranslationDocument, options);
        }
export type MenuTranslationQueryHookResult = ReturnType<typeof useMenuTranslationQuery>;
export type MenuTranslationLazyQueryHookResult = ReturnType<typeof useMenuTranslationLazyQuery>;
export type MenuTranslationQueryResult = Apollo.QueryResult<MenuTranslationQuery, MenuTranslationQueryVariables>;