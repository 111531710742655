import * as Styled from '@/src/components/Copy/CopyIcon.style';

type Props = {
	onClick?: () => void;
};

const CopyIcon = ({ onClick }: Props) => {
	return <Styled.CopyIcon onClick={onClick} type='Copy' />;
};

export default CopyIcon;
